"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

var _LotDetail = _interopRequireDefault(require("./components/LotDetail"));

var _LotDetailTotaux = _interopRequireDefault(require("./components/LotDetailTotaux"));

var _AffaireTotaux = _interopRequireDefault(require("./components/AffaireTotaux"));

var _Synthese = _interopRequireDefault(require("./components/Synthese"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _moment = _interopRequireDefault(require("moment"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'SyntheseMensuelle',
  components: {
    LotDetail: _LotDetail.default,
    LotDetailTotaux: _LotDetailTotaux.default,
    AffaireTotaux: _AffaireTotaux.default,
    Synthese: _Synthese.default
  },
  data: function data() {
    return {
      refresh: 0,
      CONST: CONST
    };
  },
  mounted: function mounted() {
    _eventBus.EventBus.$emit('REFRESH_CALCULS');
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['user']), {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  }),
  methods: {
    periodeGestion: function periodeGestion() {
      _moment.default.locale('fr');

      var periode = (0, _moment.default)(this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM').format('MMMM YYYY');
      var isValidDate = (0, _moment.default)(this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM', true).isValid();
      return this.$store.getters.affaire.currentGestMensYYYYMM && isValidDate ? 'Gestion de ' + periode : '';
    }
  }
};
exports.default = _default;