"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByAffaireId = getAllByAffaireId;
exports.getById = getById;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.bulkDelete = bulkDelete;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// export function getAll () {
//   return request({
//     url: '/commande',
//     method: 'get'
//   })
// }
function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/commande/affaire/' + id,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/commande/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/commande/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/commande',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/commande/' + id,
    method: 'delete'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/commande/' + ids,
    method: 'delete'
  });
}