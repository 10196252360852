"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var routes = [{
  path: '/affaire',
  name: 'Affaire',
  meta: {
    title: 'Gestion des affaires',
    icon: 'affaire'
  },
  redirect: '/affaire/list',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/layout'));
    });
  },
  children: [{
    path: '/affaire/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/app/affaire'));
      });
    },
    name: 'ListAffaire',
    meta: {
      title: 'Liste des affaires',
      activeMenu: '/affaire/list'
    }
  }, {
    path: '/affaire/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/app/affaire/view'));
      });
    },
    name: 'ViewAffaire',
    meta: {
      title: 'Consultation',
      activeMenu: '/affaire/list'
    }
  }, {
    path: '/affaire/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/app/affaire/new'));
      });
    },
    name: 'NewAffaire',
    meta: {
      title: 'Création',
      activeMenu: '/affaire/list'
    }
  }, {
    path: '/affaire/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/app/affaire/edit'));
      });
    },
    name: 'EditAffaire',
    meta: {
      title: 'Édition',
      activeMenu: '/affaire/list'
    }
  }]
}];
var _default = routes;
exports.default = _default;