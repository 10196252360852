"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'StickyTable',
  props: {
    classIdent: {
      type: String,
      required: true // nom de la div principale de la page où est placé le composant StickyTable

    },
    tableClass: {
      type: String,
      required: true // La référence au tableau est obligatoire

    },
    stickyOffset: {
      type: Number,
      default: 51 // Distance par rapport au haut avant de devenir sticky

    },
    top: {
      type: Number,
      default: 50
    },
    closeWidth: {
      type: Number,
      default: 170
    },
    openWidth: {
      type: Number,
      default: 360
    }
  },
  data: function data() {
    return {
      onScroll: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    var tableContainer = document.querySelector(this.tableClass);
    var table = document.querySelector('.el-table');

    if (table && tableContainer) {
      this.onScroll = function () {
        var tableRect = tableContainer.getBoundingClientRect(); // Vérifiez si l'en-tête atteint le haut de la fenêtre

        if (tableRect.top <= _this.stickyOffset) {
          table.classList.add('fixed');
          var thElement = table.querySelector('.el-table__header-wrapper .is-hidden');

          if (thElement) {
            thElement.classList.remove('is-hidden');
          }
        } else {
          table.classList.remove('fixed');
        }
      }; // Ajout d'un écouteur d'événement scroll


      window.addEventListener('scroll', this.onScroll);
    } // ajustement de la hauteur et de la largeur du tableau en fonction de l'état de la sidebar


    var style = document.createElement('style');
    style.innerHTML = "\n      ".concat(this.classIdent, " .sticky-table-container .fixed .el-table__header-wrapper {\n        top: ").concat(this.top, "px!important;\n      }\n      ").concat(this.classIdent, " .sticky-table-container .fixed .el-table__header-wrapper {\n        width: calc(100% - ").concat(this.closeWidth, "px);\n      }\n      .openSidebar ").concat(this.classIdent, " .sticky-table-container .fixed .el-table__header-wrapper {\n        width: calc(100% - ").concat(this.openWidth, "px);\n      }\n    ");
    document.head.appendChild(style);
  },
  beforeDestroy: function beforeDestroy() {
    // Supprimer l'écouteur de défilement
    window.removeEventListener('scroll', this.onScroll);
  }
};
exports.default = _default;