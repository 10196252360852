"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllBySocieteId = getAllBySocieteId;
exports.insert = insert;
exports.update = update;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAllBySocieteId(idSociete) {
  return (0, _request.default)({
    url: '/modele/societe/' + idSociete,
    method: 'get'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/modele',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/modele/' + id,
    method: 'put',
    data: data
  });
}