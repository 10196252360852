"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailGestMensuelleBudgetComp',
  props: ['idLot'],
  data: function data() {
    return {
      refresh: 0,
      show: true,
      lotsDetail: [],
      calculs: [],
      totaux: [],
      modeEdit: false,
      CONST: CONST,
      YYYYMM: '',
      YYYYMMmoins1: ''
    };
  },
  computed: {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.lotsDetail = _this.$store.getters.affaire.lotsDetail.filter(function (x) {
        return x.id_lot === _this.idLot && [CONST.LOT_DETAIL.TYPE_LIGNE.BUDGET_COMP].includes(x.type_lot_detail);
      });
      _this.calculs = _this.$store.getters.affaire.calculs.lotsDetail.filter(function (x) {
        return x.id_lot === _this.idLot && [CONST.LOT_DETAIL.TYPE_LIGNE.BUDGET_COMP].includes(x.type_lot_detail);
      });
      _this.totaux = _this.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this.idLot;
      });
      _this.show = _this.lotsDetail.length > 0; // période de gestion mensuelle

      _this.YYYYMM = _this.$store.getters.affaire.YYYYMM ? (0, _moment.default)(_this.$store.getters.affaire.YYYYMM, 'YYYYMM').format('MM/YYYY') : '';
      _this.YYYYMMmoins1 = _this.$store.getters.affaire.YYYYMMmoins1 ? (0, _moment.default)(_this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM').format('MM/YYYY') : '';
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    cellClassName: function cellClassName(_ref) {
      var columnIndex = _ref.columnIndex;

      if (columnIndex >= 11 && columnIndex <= 13) {
        return 'tb-pattern';
      }
    }
  }
};
exports.default = _default;