"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiSysParams = _interopRequireWildcard(require("@/api/sys_params"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ParametreList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      row: [],
      total: 0,
      data: [],
      multipleSelection: [],
      columns: [{
        prop: 'libelle',
        label: 'Libellé'
      }, {
        prop: 'code',
        label: 'Code',
        width: 300
      }, {
        prop: 'valeur',
        label: 'Valeur',
        width: 500
      }],
      filters: [{
        value: '',
        prop: ['libelle', 'code', 'valeur']
      }]
    };
  },
  // 9. Évènements
  // --
  mounted: function mounted() {
    this.loadData();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      apiSysParams.getAll().then(function (results) {
        _this.data = results;
        _this.data.total = results.length;
      }).catch(function (error) {
        _this.$message(error);
      }).then(function (_) {
        _this.$loader(false);
      });
    },
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm('Voulez-vous supprimer le paramètre "' + row.code + '" ?', 'Confirmer la suppression ?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiSysParams.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');
        }).catch(function (err) {
          _this2.$message(err);
        }).then(function (_) {
          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiSysParams.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();
        }).catch(function (error) {
          _this3.$message(error);
        }).then(function (_) {
          _this3.$loader(false);
        });
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;