"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encodePassword = encodePassword;
exports.ExportCsv = ExportCsv;
exports.toCurrency = toCurrency;
exports.toCurrencyFilter = toCurrencyFilter;
exports.toPercentage = toPercentage;
exports.dateToSQL = dateToSQL;
exports.getTotal = getTotal;
exports.getLabelByConstant = getLabelByConstant;
exports.getListModeReglement = getListModeReglement;
exports.getLabelModeReglement = getLabelModeReglement;
exports.getLisTypeReglement = getLisTypeReglement;
exports.getLabelTypeReglement = getLabelTypeReglement;
exports.getLabelDelaiPaiement = getLabelDelaiPaiement;
exports.generatePassword = generatePassword;
exports.dynamicSort = dynamicSort;
exports.groupByKey = groupByKey;
exports.getDateEcheanceByDelaiPaiement = getDateEcheanceByDelaiPaiement;
exports.nl2br = nl2br;
exports.toDateTime = toDateTime;
exports.toDate = toDate;
exports.toTime = toTime;
exports.getDateNow = getDateNow;
exports.getFiltreProvisionsByEcran = getFiltreProvisionsByEcran;

var _bcryptjs = _interopRequireDefault(require("bcryptjs"));

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

// import Vue from 'vue'
function encodePassword(password) {
  var salt = _bcryptjs.default.genSaltSync(10);

  var hash = _bcryptjs.default.hashSync(password, salt);

  return hash;
}

function ExportCsv(arrData) {
  var csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += [Object.keys(arrData[0]).join(';')].concat(_toConsumableArray(arrData.map(function (item) {
    return Object.values(item).join(';');
  }))).join('\n').replace(/(^\[)|(\]$)/gm, '');
  var data = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', 'export.csv');
  link.click();
}

function toCurrency(value) {
  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
}

function toCurrencyFilter(value) {
  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    useGrouping: false
  });
  return String(formatter.format(value)).replace(/,/, '.');
}

function toPercentage(value) {
  if (!isNaN(value)) {
    return Number(value).toFixed(2) + ' %';
  }
}

function dateToSQL(date) {
  if (date && date.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})/)) {
    return (0, _moment.default)(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  } else {
    return date;
  }
}

function getTotal(values) {
  var total = 0;

  if (!values.every(function (value) {
    return isNaN(value);
  })) {
    total = values.reduce(function (prev, curr) {
      var value = Number(curr);

      if (!isNaN(value)) {
        return prev + curr;
      } else {
        return prev;
      }
    }, 0);
  }

  return total;
}

function getListByConstant(dataObject) {
  var list = [];

  for (var _i = 0, _Object$entries = Object.entries(dataObject); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        row = _Object$entries$_i[1];

    list.push({
      text: row.title,
      value: row.value
    });
  }

  return list;
}

function getLabelByConstant(dataObject, value) {
  var title = 'n/c';

  if (value !== undefined) {
    for (var _i2 = 0, _Object$entries2 = Object.entries(dataObject); _i2 < _Object$entries2.length; _i2++) {
      var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
          row = _Object$entries2$_i[1];

      if (row.value === value) {
        title = row.title;
        break;
      }
    }
  }

  return title;
}

function getListModeReglement() {
  return getListByConstant(CONST.REGLEMENTS.MODE);
}

function getLabelModeReglement(value) {
  return getLabelByConstant(CONST.REGLEMENTS.MODE, value);
}

function getLisTypeReglement() {
  return getListByConstant(CONST.REGLEMENTS.TYPE);
}

function getLabelTypeReglement(value) {
  return getLabelByConstant(CONST.REGLEMENTS.TYPE, value);
}

function getLabelDelaiPaiement(value) {
  return getLabelByConstant(CONST.DELAI_PAIEMENT, value);
} // =========================================================


function generatePassword() {
  // on a enlevé les lettres sujettes à confusion
  var upper = 'ABCDEFGHJKLMNOPQRSTUVWXYZ';
  var lower = 'abcdefghjkmnpqrstvwxyz';
  var digit = '123456789';
  var all = upper + lower + digit;
  /**
   * generate random integer not greater than `max`
  */

  function rand(max) {
    return Math.floor(Math.random() * max);
  }
  /**
   * generate random character of the given `set`
   */


  function random(set) {
    return set[rand(set.length - 1)];
  }
  /**
   * generate an array with the given `length`
   * of characters of the given `set`
   */


  function generate(length, set) {
    var result = [];

    while (length--) {
      result.push(random(set));
    }

    return result;
  }
  /**
   * shuffle an array randomly
   */


  function shuffle(arr) {
    var result = [];

    while (arr.length) {
      result = result.concat(arr.splice(rand[arr.length - 1]));
    }

    return result;
  }

  var result = []; // we need to ensure we have some characters

  result = result.concat(generate(1, upper)); // 1 uppercase

  result = result.concat(generate(1, lower)); // 1 lowercase

  result = result.concat(generate(1, digit)); // 1 digit

  result = result.concat(generate(8, all)); // remaining - whatever

  return shuffle(result).join(''); // shuffle and make a string
} // =========================================================

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */


function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}
/* Retourne un tableau d'object groupé et trié par une clé */


function groupByKey(list, key) {
  return list.reduce(function (hash, obj) {
    var role = obj[key] !== null ? obj[key] : 'Non classé';

    var array = _objectSpread({}, hash, _defineProperty({}, role, (hash[obj[key]] || []).concat(obj)));

    return array;
  }, {});
} // Calcule la date d'échéance d'une facture en fonction de :
// - la date de facture
//   ET
// - du délai de paiement (affaire OU client)


function getDateEcheanceByDelaiPaiement(_ref) {
  var dateFacture = _ref.dateFacture,
      delaiPaiement = _ref.delaiPaiement;
  var dateEcheance = null;
  var date = (0, _moment.default)(dateFacture, 'YYYY/MM/DD');

  if (dateFacture && date.isValid() && delaiPaiement) {
    switch (delaiPaiement) {
      case CONST.DELAI_PAIEMENT.D_COMPTANT.value:
        dateEcheance = date.format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_30J_FIN_MOIS.value:
        dateEcheance = date.add(30, 'days').endOf('month').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_15J_DATE_FACTURE.value:
        dateEcheance = date.add(15, 'days').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_30J_DATE_FACTURE.value:
        dateEcheance = date.add(30, 'days').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_45J_FIN_MOIS.value:
        dateEcheance = date.add(45, 'days').endOf('month').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_45J_DATE_FACTURE.value:
        dateEcheance = date.add(45, 'days').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_60J_DATE_FACTURE.value:
        dateEcheance = date.add(60, 'days').format('YYYY-MM-DD');
        break;
    }
  }

  return dateEcheance;
}

function nl2br(val) {
  return val ? val.replace(/\n/g, '<br />') : '';
}

function toDateTime(value) {
  if (value && typeof value !== 'undefined') {
    return _moment.default.parseZone(String(value)).utc().format('DD/MM/YYYY [à] HH[H]mm');
  }
}

function toDate(value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YYYY');
  }
}

function toTime(value) {
  if (value && typeof value !== 'undefined') {
    return _moment.default.parseZone(String(value)).utc().format('HH[H]mm'); // return moment(String(value)).format('HH[H]mm')
  }
}

function getDateNow() {
  return (0, _moment.default)();
}

function getFiltreProvisionsByEcran(activeMenu) {
  var filtreProvisions;

  switch (activeMenu) {
    case 'objectifsDebourses':
      filtreProvisions = CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value;
      break;

    case 'budgetAffaire':
      filtreProvisions = CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value;
      break;

    default:
      filtreProvisions = null;
  }

  return filtreProvisions;
}