"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommandeInfos',
  props: {
    commande: {
      type: Object
    },
    montantFacture: {
      type: Number,
      default: 0
    },
    montantFactureInitial: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      spanCol1: 8,
      spanCol2: 16,
      spanCol3: 3
    };
  },
  computed: {
    montantCumuleFacture: function montantCumuleFacture() {
      return this.commande.montant_facture - this.montantFactureInitial;
    },
    montantRestantAFacturer: function montantRestantAFacturer() {
      return this.commande.montant - this.montantCumuleFacture;
    },
    solde: function solde() {
      var solde = this.montantRestantAFacturer - this.montantFacture;
      solde = Number(solde).toFixed(2) === '-0.00' ? 0 : solde;
      return solde;
    },
    pourcentageAvancement: function pourcentageAvancement() {
      return (this.commande.montant - this.solde) / this.commande.montant;
    }
  },
  watch: {
    /* ---------------------------------------
     *  Solde
     * ---------------------------------------
     *
     *   - si le solde est négatif on l'indique à l'utilisateur
     *
     */
    solde: function solde(newVal, oldVal) {
      if (newVal < 0 && oldVal >= 0) {
        // this.$alert('Le montant total à facturer dépasse le montant de la commande')
        this.$alert('Le montant total à facturer dépasse le montant de la commande', 'Information', {
          confirmButtonText: 'OK',
          type: 'warning'
        });
        /*
        this.$message({
          message: 'Le montant total à facturer dépasse le montant de la commande',
          type: 'warning'
        })
        */
      }
    }
  }
};
exports.default = _default;