"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.getById = getById;
exports.getDefault = getDefault;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.bulkDelete = bulkDelete;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAll() {
  return (0, _request.default)({
    url: '/tva',
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/tva/' + id,
    method: 'get'
  });
}

function getDefault() {
  return (0, _request.default)({
    url: '/tva/default',
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/tva/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/tva',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/tva/' + id,
    method: 'delete'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/tva/' + ids,
    method: 'delete'
  });
}