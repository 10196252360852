"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'CommandeForm',
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  data: function data() {
    return {
      rules: {
        date_commande: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        montant_initial: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        numero: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['affaire'])),
  methods: _objectSpread({}, (0, _vuex.mapActions)('facturation', ['insertCommande', 'updateCommande']), {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      var commande = data;
      this.insertCommande(commande).then(function () {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);

        _eventBus.EventBus.$emit('COMMANDE_SAVE');
      }).catch(function (error) {
        _this2.$loader(false);

        _this2.$message(error);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);

      var commande = _objectSpread({}, data, {
        id: this.formData.id
      });

      this.updateCommande(commande).then(function () {
        _this3.$message('Mise à jour effectuée avec succès');

        _this3.resetForm();

        _this3.$loader(false);

        _eventBus.EventBus.$emit('COMMANDE_SAVE');
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    },
    getFormData: function getFormData() {
      return {
        id_affaire: this.affaire.affaire.id,
        numero: this.formData.numero,
        date_commande: this.formData.date_commande,
        montant_initial: this.formData.montant_initial,
        avenant_1: this.formData.avenant_1,
        avenant_2: this.formData.avenant_2,
        avenant_3: this.formData.avenant_3,
        montant: this.formData.montant,
        emetteur: this.formData.emetteur,
        libelle: this.formData.libelle,
        commentaire: this.formData.commentaire
      };
    },
    handleMontantFinal: function handleMontantFinal() {
      var mtInitial = isNaN(this.formData.montant_initial) ? 0 : Number(this.formData.montant_initial);
      var mtAvenant1 = isNaN(this.formData.avenant_1) ? 0 : Number(this.formData.avenant_1);
      var mtAvenant2 = isNaN(this.formData.avenant_2) ? 0 : Number(this.formData.avenant_2);
      var mtAvenant3 = isNaN(this.formData.avenant_3) ? 0 : Number(this.formData.avenant_3);
      var mtFinal = mtInitial + mtAvenant1 + mtAvenant2 + mtAvenant3; // console.log('mtFinal', mtInitial, mtAvenant1, mtAvenant2, mtAvenant3, mtFinal)

      this.formData.montant = mtFinal;
    }
  })
};
exports.default = _default;