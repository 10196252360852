"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAffaireHistoByIdAnneeMois = getAffaireHistoByIdAnneeMois;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAffaireHistoByIdAnneeMois(idAffaire, anneemois) {
  // console.log('getAffaireHistoByIdAnneeMois', idAffaire, anneemois)
  return (0, _request.default)({
    url: "/affaire_histo/".concat(idAffaire, "/").concat(anneemois),
    method: 'get'
  });
}