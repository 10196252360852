"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newGestMens = newGestMens;
exports.getLastGestMens = getLastGestMens;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function newGestMens(idSociete, periodeRef) {
  return (0, _request.default)({
    url: '/gestmens/new/' + idSociete + '/' + periodeRef,
    method: 'get'
  });
}

function getLastGestMens(idSociete) {
  return (0, _request.default)({
    url: '/gestmens/last/' + idSociete,
    method: 'get'
  });
}