"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['acl'],
  data: function data() {
    return {
      routes: [],
      checkStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    };
  },
  computed: {
    routesData: function routesData() {
      return this.routes;
    }
  },
  created: function created() {
    this.routes = this.generateRoutes(this.$router.options.routes);
  },
  methods: {
    // Reshape the routes structure so that it looks the same as the sidebar
    generateRoutes: function generateRoutes(routes) {
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var res = [];

      var _iterator = _createForOfIteratorHelper(routes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var route = _step.value;
          // skip some route
          var excludeFromTreeAcl = ['/', '/login', '/404', '/401', '/home', '*', '/userprofil', '/version', '/password-reminder', '/password-reset/:pwdResetToken'];
          var treeAcl = route.meta && route.meta.treeAcl === 'exclude' || false; // console.log(route.path, route.name, excludeFromTreeAcl, treeAcl)

          if (excludeFromTreeAcl.includes(route.path) || treeAcl) {
            continue;
          }

          var data = {
            path: route.name,
            title: route.meta && route.meta.title
          }; // recursive child routes

          if (route.children) {
            data.children = this.generateRoutes(route.children, data.path);
          }

          res.push(data);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return res;
    },
    handleCheck: function handleCheck() {
      var checkedKeys = this.$refs.tree.getCheckedKeys(); // const selection = checkedKeys

      var checkedHalfKeys = this.$refs.tree.getHalfCheckedKeys(); // const selection = checkedKeys.concat(checkedHalfKeys)

      this.$emit('update:acl', JSON.stringify(checkedKeys));
      this.$emit('update:rootacl', JSON.stringify(checkedHalfKeys));
    }
  }
};
exports.default = _default;