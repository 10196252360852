"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiNotification = _interopRequireWildcard(require("@/api/notification"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var state = {
  unreadCounter: 0,
  readCounter: 0,
  notifications: []
};
var mutations = {
  SET_UNREADCOUNTER: function SET_UNREADCOUNTER(state, unreadCounter) {
    state.unreadCounter = unreadCounter;
  },
  SET_READCOUNTER: function SET_READCOUNTER(state, readCounter) {
    state.readCounter = readCounter;
  },
  SET_NOTIFICATIONS: function SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  }
};
var actions = {
  read: function read(_ref, idUser) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      apiNotification.getAllByUserId(idUser).then(function (results) {
        if (JSON.stringify(results) !== JSON.stringify(state.notifications)) {
          commit('SET_NOTIFICATIONS', results);
          commit('SET_READCOUNTER', results.filter(function (x) {
            return x.date_markasread !== null;
          }).length);
          commit('SET_UNREADCOUNTER', results.filter(function (x) {
            return x.date_markasread === null;
          }).length);
        }

        resolve(results);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markasread: function markasread(_ref2, notification) {
    var dispatch = _ref2.dispatch;
    return new Promise(function (resolve, reject) {
      var data = {
        date_markasread: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')
      }; // mark as read

      apiNotification.update(notification.id, data).then(function (result) {
        if (result.affectedRows > 0) {
          dispatch('read', notification.id_user);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markallasread: function markallasread(_ref3, idUser) {
    var dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      var data = {
        id_user: idUser
      }; // mark all user notifications as read

      apiNotification.markAllAsRead(data).then(function (result) {
        if (result.affectedRows > 0) {
          dispatch('read', idUser);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markasnotified: function markasnotified(commit, notification) {
    return new Promise(function (resolve, reject) {
      var data = {
        date_notification: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')
      }; // mark as notified

      apiNotification.update(notification.id, data).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  markasdeleted: function markasdeleted(commit, notification) {
    return new Promise(function (resolve, reject) {
      var selection = notification.map(function (u) {
        return u.id;
      }).join(','); // mark as deleted

      apiNotification.markAsDeleted(selection).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;