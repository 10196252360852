"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SageList = _interopRequireDefault(require("@/components/SageList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SageIndex',
  components: {
    SageList: _SageList.default
  },
  data: function data() {
    return {
      activeMenu: 'sage_ligne_1000',
      factureId: null
    };
  }
};
exports.default = _default;