"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterProtectedRoutes = filterProtectedRoutes;
exports.default = void 0;

var _router = require("@/router");

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function hasPermission(route) {
  // console.log(route.name, state.acl)
  if (route.name) {
    var acl = state.acl ? state.acl.includes(route.name) : false;
    var rootacl = state.rootacl ? state.rootacl.includes(route.name) : false;
    return acl || rootacl;
  } else {
    return false;
  }
}

function filterProtectedRoutes(routes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(tmp)) {
      if (tmp.children) {
        tmp.children = filterProtectedRoutes(tmp.children);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  // addRoutes: [],
  permission: [],
  rules: [],
  acl: [],
  rootacl: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    // state.addRoutes = routes
    state.routes = routes;
  },
  SET_PERMISSION: function SET_PERMISSION(state, permission) {
    state.permission = permission;
    state.acl = state.permission.acl;
    state.rootacl = state.permission.rootacl;
    state.rules = state.permission.rules;
  },
  SET_ACL: function SET_ACL(state, _ref) {
    var acl = _ref.acl,
        rootacl = _ref.rootacl;
    state.acl = acl;
    state.rootacl = rootacl;
    state.permission.acl = acl;
    state.permission.rootacl = rootacl;
  },
  SET_RULES: function SET_RULES(state, rules) {
    state.rules = rules;
    state.permission.rules = rules;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref2, user) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      var accessedRoutes = filterProtectedRoutes(_router.Routes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  getPermission: function getPermission(_ref3, user) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      apiPermission.getBySocieteRole(user.id_societe, user.key_role).then(function (response) {
        var permissions = response[0];
        commit('SET_PERMISSION', permissions);
        commit('SET_ACL', {
          acl: permissions.acl,
          rootacl: permissions.rootacl
        });
        commit('SET_RULES', permissions.rules); // console.log(permissions)

        resolve(permissions);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;