"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _eventBus = require("@/helpers/event-bus");

var _SyntheseList = _interopRequireDefault(require("@/components/SyntheseList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'SyntheseIndex',
  components: {
    SyntheseList: _SyntheseList.default
  },
  data: function data() {
    return {
      activeMenu: 'synthese_liste',
      factureId: null
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)('facturation', ['getFactureById'])),
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('SYNTHESE_REGLEMENTS_RETURN', function () {
      // this.$router.push({ name: 'VenteCreance.Creances' })
      _this.activeMenu = 'synthese_liste';
      _this.factureId = null;
      _this.$route.meta.title = 'Synthèse générale';
    });

    _eventBus.EventBus.$on('SYNTHESE_REGLEMENTS', function (key, row) {
      var nextTickEventName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var factureId = parseInt(row.facture.id);

      _this.loadFactureById(factureId).then(function (results) {
        var facture = _this.getFactureById(factureId);

        if (!facture) {
          facture = results[0];
        }

        if (facture) {
          _this.$route.meta.title = "R\xE8glements de la facture ".concat(facture.numero);
          _this.factureId = factureId;
          _this.activeMenu = key; // Attente du prochain rendu du DOM pour déclencher un nouvel événement

          if (nextTickEventName) {
            _this.$nextTick(function () {
              _eventBus.EventBus.$emit(nextTickEventName);
            });
          }
        } else {
          _this.$router.push({
            name: '404'
          });
        }
      }).catch(function (_) {
        _eventBus.EventBus.$emit('SYNTHESE_REGLEMENTS_RETURN');
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('SYNTHESE_REGLEMENTS_RETURN');

    _eventBus.EventBus.$off('SYNTHESE_REGLEMENTS');
  },
  methods: _objectSpread({}, (0, _vuex.mapActions)('facturation', ['loadFactureById']))
};
exports.default = _default;