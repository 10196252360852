"use strict";

var _vue = _interopRequireDefault(require("vue"));

var _moment = _interopRequireDefault(require("moment"));

var _utils = require("@/helpers/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue.default.filter('toCurrency', function (value) {
  if (value === '' || value === null || typeof value === 'undefined' || isNaN(value)) return;
  if (value === 0) value = Math.abs(value); // pour éviter d'afficher le signe - quand value = 0

  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  var result = formatter.format(value);
  result = result.toString().substring(0, 5) === '-0,00' ? '0,00 €' : result;
  return result; // return formatter.format(value)
});

_vue.default.filter('toCurrencyWithZero', function (value) {
  if (typeof value === 'undefined') return;
  if (value === 0) value = Math.abs(value); // pour éviter d'afficher le signe - quand value = 0

  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

_vue.default.filter('toPercentage', function (value) {
  if (!isNaN(value)) {
    var pc = Number(value * 100).toFixed(2);
    return (pc.toString().length === 6 ? Math.trunc(pc) : pc) + ' %'; // => pour éviter d'afficher 100.00 % mais plutot 100 %
    // return Number(value * 100).toFixed(2) + ' %'
  }
});

_vue.default.filter('toPercentageFormat', function (value) {
  if (!isNaN(value)) {
    return Number(value) + ' %';
  }
});

_vue.default.filter('toDate', function (value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YYYY');
  }
});

_vue.default.filter('toDateMY', function (value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('MM/YYYY');
  }
});

_vue.default.filter('toDateTime', function (value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YYYY HH:mm');
  }
});

_vue.default.filter('toShortDate', function (value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YY');
  }
}); // Le filtre utilise la fonction getLabelModeReglement déjà implémentée dans utils


_vue.default.filter('getLabelModeReglement', function (value) {
  return (0, _utils.getLabelModeReglement)(value);
}); // Le filtre utilise la fonction getLabelTypeReglement déjà implémentée dans utils


_vue.default.filter('getLabelTypeReglement', function (value) {
  return (0, _utils.getLabelTypeReglement)(value);
}); // Le filtre utilise la fonction getLabelDelaiPaiement déjà implémentée dans utils


_vue.default.filter('getLabelDelaiPaiement', function (value) {
  return (0, _utils.getLabelDelaiPaiement)(value);
}); // Le filtre utilise la fonction nl2br déjà implémentée dans utils


_vue.default.filter('nl2br', function (value) {
  return (0, _utils.nl2br)(value);
});