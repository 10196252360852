"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiHistorique = _interopRequireWildcard(require("@/api/historique"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HistoriqueAffaire',
  data: function data() {
    return {
      data: [],
      total: 0,
      filters: [{
        value: '',
        prop: []
      }],
      CONST: CONST
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.$loader(true);
    apiHistorique.getAllByAffaireId(this.$route.params.id).then(function (results) {
      // filtrage pour ne pas afficher les messages avec id_user = -1 mis en place par les traitements auto ou manuel
      // pour les affaires qui avait un status 7,30,31,32,40 au moment du passage du traitement
      _this.data = results.filter(function (x) {
        return x.id_user !== -1;
      });
      _this.data.total = _this.data.length;

      _this.$loader(false);
    }).catch(function (error) {
      _this.$loader(false);

      _this.$message(error);
    });
  },
  methods: {
    handleCurrentChange: function handleCurrentChange(currentRow) {
      this.row = currentRow;
    },
    status: function status(value) {
      var search = Object.values(CONST.AFFAIRE.STATUS).find(function (x) {
        return x.value === value;
      });
      return search ? search.title : '';
    },
    etape: function etape(value) {
      var search = Object.values(CONST.AFFAIRE.ETAPE).find(function (x) {
        return x.value === value;
      });
      return search ? search.title : '';
    }
  }
};
exports.default = _default;