"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("element-ui/lib/theme-chalk/index.css");

require("element-tiptap/lib/index.css");

var _elementTiptap = require("element-tiptap");

var _vuex = require("vuex");

var apiCommentaire = _interopRequireWildcard(require("@/api/commentaire"));

var _moment = _interopRequireDefault(require("moment"));

var apiHistorique = _interopRequireWildcard(require("@/api/historique"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'dlgEditor',
  props: ['idAffaire', 'commentaire', 'action', 'visible'],
  components: {
    'el-tiptap': _elementTiptap.ElementTiptap
  },
  data: function data() {
    return {
      extensions: [new _elementTiptap.Doc(), new _elementTiptap.Text(), new _elementTiptap.Paragraph(), // ---
      new _elementTiptap.Bold(), new _elementTiptap.Underline(), new _elementTiptap.Italic(), new _elementTiptap.Strike(), new _elementTiptap.ListItem(), new _elementTiptap.BulletList(), new _elementTiptap.OrderedList(), new _elementTiptap.TextColor(), new _elementTiptap.TextHighlight(), new _elementTiptap.TodoItem(), new _elementTiptap.TodoList(), new _elementTiptap.FormatClear(), new _elementTiptap.Preview(), new _elementTiptap.Fullscreen()],
      // editor's content
      content: ''
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['user'])),
  methods: {
    handleOpened: function handleOpened() {
      if (this.action === 'edit') {
        this.content = this.commentaire.message;
      } else {
        this.content = '';
      }
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    handleUpdate: function handleUpdate() {
      var _this = this;

      this.$loader(true);
      var data = {
        message: this.content.trim(),
        date_modification: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')
      };
      apiCommentaire.update(this.commentaire.id, data).then(function () {
        apiHistorique.insert({
          id_affaire: _this.idAffaire,
          id_user: _this.user.id,
          message: 'Modification du commentaire id#' + _this.commentaire.id
        }).then(function () {
          _this.$loader(false);

          _this.handleClose();
        });
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    handleSave: function handleSave() {
      var _this2 = this;

      this.$loader(true);
      var data = {
        id_affaire: this.idAffaire,
        id_user: this.user.id,
        utilisateur: this.user.firstname + ' ' + this.user.lastname,
        email: this.user.email,
        message: this.content.trim()
      };
      apiCommentaire.insert(data).then(function (results) {
        apiHistorique.insert({
          id_affaire: _this2.idAffaire,
          id_user: _this2.user.id,
          message: 'Ajout du commentaire id#' + results.insertId
        }).then(function () {
          _this2.$loader(false);

          _this2.handleClose();
        });
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    }
  }
};
exports.default = _default;