"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _constantes = require("@/helpers/constantes");

var _vuex = require("vuex");

var _FactureSelectList = _interopRequireDefault(require("@/components/FactureSelectList"));

var _FactureInfos = _interopRequireDefault(require("@/components/FactureInfos"));

var _ReglementInfos = _interopRequireDefault(require("../ReglementInfos"));

var _ReglementModeSelectList = _interopRequireDefault(require("@/components/ReglementModeSelectList"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ReglementForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  inject: ['getFacture'],
  components: {
    FactureSelectList: _FactureSelectList.default,
    FactureInfos: _FactureInfos.default,
    ReglementInfos: _ReglementInfos.default,
    ReglementModeSelectList: _ReglementModeSelectList.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    var _this = this;

    var validDateReglement = function validDateReglement(rule, value, callback) {
      var date = value;

      if (!(0, _moment.default)(date, 'YYYY/MM/DD').isValid()) {
        callback(new Error('Date de règlement invalide'));
      }

      callback();
    };

    var validIdFacture = function validIdFacture(rule, value, callback) {
      // Attention: pour une raison inexpliquée `value` vaut undefined alors que `this.formData.id_facture` possède la valeur correcte
      var idFacture = _this.formData.id_facture;

      if (!idFacture) {
        callback(new Error('Veuillez sélectionner une facture'));
      }

      callback();
    };

    var validIdImputation = function validIdImputation(rule, value, callback) {
      // Attention: bizarre car ici, pour une raison inexpliquée aussi, `value` ET `this.formData.id_imputation` possèdent la valeur correcte
      var idImputation = value;

      if (_this.formData.mode === _this.CONST.REGLEMENTS.MODE.IMPUTATION.value && !idImputation) {
        callback(new Error('Veuillez sélectionner une facture d\'imputation'));
      }

      callback();
    };

    var validMontant = function validMontant(rule, value, callback) {
      if (_this.disabledMontant) {
        callback();
      }

      var idImputation = _this.formData.id_imputation;
      var soldeFactureImputation = _this.montantImputable; // Réglement par Imputation

      if (_this.formData.mode === _this.CONST.REGLEMENTS.MODE.IMPUTATION.value) {
        // Le montant du règlement par imputation ne peut dépasser le solde imputable disponible
        if (idImputation && _this.formData.montant > soldeFactureImputation) {
          callback(new Error("Le montant ne peut pas \xEAtre sup\xE9rieur au solde de la facture \xE0 imputer, soit ".concat(soldeFactureImputation, " \u20AC maximum")));
        }
      } // Réglement de trop-perçu


      if (_this.formData.type === _this.CONST.REGLEMENTS.TYPE.TROP_PERCU.value) {
        // Le montant du règlement d'un trop-perçu doit être négatif
        if (_this.formData.montant >= 0) {
          callback(new Error('Le montant de règlement d\'un trop-perçu doit être négatif'));
        }
      }
      /*
      else {
        // Le montant du règlement doit être positif
        if (this.formData.montant < 0) {
          callback(new Error('Le montant de règlement doit être positif'))
        }
      }
      */


      callback();
    };

    return {
      selection: {},
      CONST: {
        REGLEMENTS: _constantes.REGLEMENTS
      },
      // L'idFacture est-il forcé par la facture parente du règlement à créer / modifier ?
      disabledIdFactureSelect: false,
      // Le champ montant doit-il être désactivé
      disabledMontant: false,
      // L'idFacture initialement lié au règlement actuel
      idFactureInitial: null,
      // L'idImputation initialement lié au règlement actuel
      idImputationInitial: null,
      // Montant du règlement initial (0 en création mais égal au montant en édition)
      montantReglementInitial: 0,
      // Montant du règlement initialement imputé (0 en création mais égal au montant en édition)
      montantImputationInitial: 0,
      rules: {
        id_facture: [// { required: true, message: 'Champ obligatoire' },
        {
          validator: validIdFacture,
          trigger: 'change'
        }],
        id_imputation: [{
          validator: validIdImputation,
          trigger: 'change'
        }],
        mode: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        date_reglement: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validDateReglement
        }],
        montant: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validMontant
        }]
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)('facturation', ['getFactureListForCurrentAffaire']), {
    formTitle: function formTitle() {
      var title = this.title;

      switch (this.formData.type) {
        case this.CONST.REGLEMENTS.TYPE.TROP_PERCU.value:
          title += ' de trop-perçu';
          break;
      }

      return title;
    },
    montantReglement: function montantReglement() {
      var montant = Number(this.formData.montant);
      return isNaN(montant) ? 0 : montant;
    },
    montantFactureRestantDu: function montantFactureRestantDu() {
      var montant = Number(this.selection.facture.mt_a_percevoir_ttc) - Number(this.selection.facture.montant_regle) + this.montantReglementInitial;
      return isNaN(montant) ? 0 : montant;
    },
    montantImputable: function montantImputable() {
      var montantImputable = 0;

      if (this.selection.imputation) {
        montantImputable = Math.abs(this.selection.imputation.solde) + this.montantImputationInitial;
      }

      return montantImputable;
    },
    showImputableFacture: function showImputableFacture() {
      var modeIsImputation = this.formData.mode === this.CONST.REGLEMENTS.MODE.IMPUTATION.value;
      return modeIsImputation;
    }
  }),
  // 9. Évènements
  // --
  watch: {
    // Vider le champ 'id_imputation' quand le mode de règlement n'est plus imputation
    'formData.mode': function formDataMode(newVal, oldVal) {
      if (oldVal === this.CONST.REGLEMENTS.MODE.IMPUTATION.value) {
        this.formData.id_imputation = null;
      }
    },
    'selection.facture': function selectionFacture(newVal) {
      this.setMontantReglementInitial();
    },
    'selection.imputation': function selectionImputation(newVal) {
      this.setMontantImputationInitial();
    }
  },
  created: function created() {
    var vm = this; // Récupération de l'ID de la facture parente sélectionnée
    // pour lier automatiquement le règlement à cette facture

    vm.getFacture(function (facture) {
      if (facture) {
        vm.formData.id_facture = facture.id;
        vm.disabledIdFactureSelect = vm.formData.id_facture > 0;
      }
    });
  },
  mounted: function mounted() {
    this.idFactureInitial = this.formData.id_facture;
    this.idImputationInitial = this.formData.id_imputation;
  },
  // 10. Propriétés non réactives
  // --
  methods: _objectSpread({}, (0, _vuex.mapActions)('facturation', ['insertReglement', 'updateReglement']), {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          new Promise(function (resolve, reject) {
            // const soldeRestantDu = Number.parseFloat(this.montantFactureRestantDu).toFixed(2)
            var soldeRestantDu = Number.parseFloat(_this2.montantFactureRestantDu.toFixed(2));
            var montant = Number.parseFloat(Number.parseFloat(_this2.formData.montant).toFixed(2));

            if (montant <= soldeRestantDu) {
              return resolve(_this2.action);
            } // Si le montant du règlement dépasse le solde restant dû de la facture on l'induqe par un message de notification


            _this2.$confirm("<strong>Le montant du r\xE8glement saisi d\xE9passe le solde restant d\xFB sur la facture</strong>.<br>Si vous continuez, un trop-per\xE7u sera enregistr\xE9.", 'Enregistrement d\'un trop-perçu', {
              type: 'warning',
              confirmButtonText: 'OK',
              cancelButtonText: 'Annuler',
              dangerouslyUseHTMLString: true
            }).then(function (result) {
              return resolve(_this2.action);
            }).catch(function (error) {
              if (error !== 'cancel') {
                console.log(error);

                _this2.$message(error);
              }
            });
          }).then(function (result) {
            switch (_this2.action) {
              case 'new':
                _this2.actionNew();

                break;

              case 'edit':
                _this2.actionEdit();

                break;
            }
          });
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      console.log('[Reglement/actionNew] 1.1. Création du reglement en BDD');
      this.insertReglement(data).then(function (results) {
        console.log('[Reglement/actionNew] 1.2. Reglement créé en BDD');

        _this3.$message('Création effectuée avec succès');

        _this3.resetForm();

        _this3.$loader(false);

        console.log('[Reglement/actionNew] 2.3 emit event REGLEMENT_SAVE');

        _eventBus.EventBus.$emit('REGLEMENT_SAVE');
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      var data = this.getFormData();
      this.$loader(true);

      var reglement = _objectSpread({
        id: this.formData.id
      }, data);

      console.log('[Reglement/actionEdit] 1.1. Modification du reglement ' + reglement.id + ' en BDD');
      this.updateReglement(reglement).then(function (results) {
        console.log('[Reglement/actionEdit] 1.2. Reglement ' + reglement.id + ' modifié en BDD');

        _this4.$message('Mise à jour effectuée avec succès');

        _this4.resetForm();

        _this4.$loader(false);

        console.log('[Reglement/actionEdit] 2.3 emit event REGLEMENT_SAVE');

        _eventBus.EventBus.$emit('REGLEMENT_SAVE');
      }).catch(function (error) {
        _this4.$loader(false);

        _this4.$message(error);
      });
    },
    getFormData: function getFormData() {
      return {
        id_facture: this.formData.id_facture,
        id_imputation: this.formData.id_imputation,
        mode: this.formData.mode,
        type: this.formData.type,
        date_reglement: this.formData.date_reglement,
        montant: Number.isNaN(Number.parseFloat(this.formData.montant)) ? 0 : Number.parseFloat(this.formData.montant),
        commentaire: this.formData.commentaire
      };
    },
    setMontantReglementInitial: function setMontantReglementInitial() {
      var montantReglementInitial = 0;

      if (this.formData.id_facture !== null && this.selection.facture && this.idFactureInitial === this.selection.facture.id) {
        montantReglementInitial = Number.parseFloat(this.formData.montant) || 0;
      }

      this.montantReglementInitial = montantReglementInitial;
    },
    setMontantImputationInitial: function setMontantImputationInitial() {
      var montantImputationInitial = 0;

      if (this.formData.id_imputation !== null && this.selection.imputation && this.idImputationInitial === this.selection.imputation.id) {
        montantImputationInitial = Number.parseFloat(this.formData.montant) || 0;
      }

      this.montantImputationInitial = montantImputationInitial;
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;