"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  formData: {},
  // Contient les données du formulaire pour chaque page
  isRoute: false
};
var mutations = {
  setFormData: function setFormData(state, _ref) {
    var pageKey = _ref.pageKey,
        data = _ref.data;
    state.formData[pageKey] = data; // Sauvegarde les données pour une page spécifique
  },
  setRoute: function setRoute(state, value) {
    state.isRoute = value; // Met à jour la variable globale
  }
};
var actions = {
  resetFormData: function resetFormData(_ref2, pageKey) {
    var commit = _ref2.commit;
    commit('setFormData', {
      pageKey: pageKey,
      data: {}
    }); // Met à jour les données du formulaire dans l'état

    localStorage.removeItem("Filters_".concat(pageKey)); // Supprime les donnes du localStorage
  },
  updateFormData: function updateFormData(_ref3, _ref4) {
    var commit = _ref3.commit;
    var pageKey = _ref4.pageKey,
        data = _ref4.data;
    commit('setFormData', {
      pageKey: pageKey,
      data: data
    }); // Met à jour les données du formulaire dans l'état

    localStorage.setItem("Filters_".concat(pageKey), JSON.stringify(data)); // Sauvegarde dans le localStorage
  },
  loadFormData: function loadFormData(_ref5, pageKey) {
    var commit = _ref5.commit;
    var savedData = localStorage.getItem("Filters_".concat(pageKey));

    if (savedData) {
      var parsedData = JSON.parse(savedData);
      commit('setFormData', {
        pageKey: pageKey,
        data: parsedData
      });
      return parsedData; // Retourne les données pour une utilisation immédiate
    }

    return null; // Retourne null si aucune donnée n'est trouvée
  },
  updateRoute: function updateRoute(_ref6, value) {
    var commit = _ref6.commit;
    commit('setRoute', value); // Action pour mettre à jour l'état
  }
};
var getters = {
  getFormData: function getFormData(state) {
    return function (pageKey) {
      return state.formData[pageKey] || null; // Retourne les données pour une page spécifique
    };
  },
  getRoute: function getRoute(state) {
    return state.isRoute;
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
exports.default = _default;