"use strict";

var _vue = _interopRequireDefault(require("vue"));

var _store = _interopRequireDefault(require("@/store"));

var _inputmask = _interopRequireDefault(require("inputmask"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

_vue.default.directive('mask', {
  // https://github.com/RobinHerbots/Inputmask#usage
  // https://robinherbots.github.io/Inputmask/
  bind: function bind(el, binding) {
    (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  }
}); // initialise la valeur d'un champ
// ex: <input v-model="subscribed" type="checkbox" v-init:subscribed="false"  />


_vue.default.directive('init', {
  bind: function bind(el, binding, vnode) {
    vnode.context[binding.arg] = binding.value;
  }
});

_vue.default.directive('permission', {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var role = _store.default.getters && _store.default.getters.user && _store.default.getters.user.role;

    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = permissionRoles.includes(role);

      if (!hasPermission && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
    }
  }
}); // renvoit l'intersection entre 2 tableaux (elements en commun)


function intersect(a, b) {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set(_toConsumableArray(setA).filter(function (x) {
    return setB.has(x);
  }));
  var res = Array.from(intersection);
  return res;
} // exemple d'appel pour tester 1 valeur: v-can:myrule="'edit'"
// exemple d'appel pour tester plusieurs valeurs: v-can:myrule="'edit,validate'"


_vue.default.directive('can', {
  inserted: function inserted(el, binding, vnode) {
    var permission = _store.default.getters && _store.default.getters.rules && _store.default.getters.rules.length > 0 && JSON.parse(_store.default.getters.rules);
    var userRules = permission && permission[binding.arg] && permission[binding.arg].split(',');
    var testRules = binding.value && binding.value.split(',') || [];
    var intersections = intersect(userRules, testRules);

    if ((!intersections || intersections.length === 0) && vnode.elm && vnode.elm.parentElement) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
}); // exemple d'appel pour tester 1 valeur: v-cant:myrule="'edit'"
// exemple d'appel pour tester plusieurs valeurs: v-cant:myrule="'edit,validate'"


_vue.default.directive('cant', {
  inserted: function inserted(el, binding, vnode) {
    var permission = _store.default.getters && _store.default.getters.rules && _store.default.getters.rules.length > 0 && JSON.parse(_store.default.getters.rules);
    var userRules = permission && permission[binding.arg] && permission[binding.arg].split(',');
    var testRules = binding.value && binding.value.split(',') || [];
    var intersections = intersect(userRules, testRules);

    if (intersections && intersections.length > 0 && vnode.elm && vnode.elm.parentElement) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
});

_vue.default.directive('numberonly', {
  bind: function bind(el, binding, vnode) {
    el.addEventListener('keydown', function (e) {
      // pour controler les touches autorisées du clavier
      var key = e.key;
      var allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab']; // un seul signe negatif et decimal

      var tirets = key === '-' && e.target.value.indexOf('-') !== -1;
      var points = key === '.' && e.target.value.indexOf('.') !== -1;

      if (key && (!allowedKeys.includes(key) || tirets || points)) {
        e.preventDefault();
        return false;
      }
    });
    el.addEventListener('input', function (e) {
      var nbDec = 2; // valeur par défaut du nombre de décimale à controler

      nbDec = binding.modifiers['dec2'] ? 2 : nbDec;
      nbDec = binding.modifiers['dec3'] ? 3 : nbDec; // pour valider un numerique, 2 chiffres après la virgule uniquement, positif ou negatif

      var reg = new RegExp('^-?([0-9]+.?([0-9]{1,' + nbDec + '})?)?$', 'g');

      if (!reg.test(e.target.value)) {
        e.target.value = vnode.data.model.value;
      }

      var s = e.target.value;

      if (s.indexOf('-') > -1) {
        e.target.value = '-' + s.replace(/-/, '');
      } // e.target.value = isNaN(e.target.value) ? 0 : e.target.value


      vnode.data.model.value = e.target.value;
    }, true);
  }
}); // Registering a global custom directive called `v-focus`


_vue.default.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function inserted(el) {
    // Focus the element
    el.focus();
  }
});

_vue.default.directive('test', {
  bind: function bind(el, binding) {// el.class = binding.arg
  }
}); // appel par: v-save-input:users
// donner un identifiant unique derriere v-save-input:


_vue.default.directive('save-input', {
  bind: function bind(el, binding, vnode) {
    var tag = binding.rawName || 'v-save-input';

    function setModelValue(vnode, value) {
      // standard v-model
      if (vnode.data.model) {
        return vnode.data.model.callback(value);
      } // component with custom v-model
      // eslint-disable-next-line no-eval


      return eval("vnode.context.$data.".concat(vnode.data.directives.find(function (directive) {
        return directive.name === 'model';
      }).expression, " = value"));
    }

    var storage = window.localStorage.getItem(tag) || '';

    if (storage !== '') {
      setModelValue(vnode, storage); // console.log('getItem.localStorage', tag, storage)
    }
  },
  update: function update(el, binding, vnode) {
    var tag = binding.rawName || 'v-save-input';
    var storage = window.localStorage.getItem(tag) || '';

    if (vnode.data.model && storage !== vnode.data.model.value) {
      window.localStorage.setItem(tag, vnode.data.model.value); // console.log('setItem.localStorage', tag, vnode.data.model.value)
    }
  }
});