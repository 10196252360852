"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiLibelleFacture = _interopRequireWildcard(require("@/api/libelle_facture"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LibelleFactureSelectList',
  props: {
    LibelleFactureSelected: {
      type: String,
      default: null
    },
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    }
  },
  data: function data() {
    return {
      LibelleFactureList: [],
      LibelleFacture: this.LibelleFactureSelected
    };
  },
  mounted: function mounted() {
    this.getAll();
  },
  methods: {
    getAll: function getAll() {
      var _this = this;

      apiLibelleFacture.getAll().then(function (results) {
        _this.LibelleFactureList = results.filter(function (x) {
          return x.actif === 1 || x.libelle === _this.LibelleFactureSelected;
        });

        if (_this.action === 'new') {
          var defaut = _this.LibelleFactureList.filter(function (x) {
            return x.actif === 1 && x.idc_defaut === 1;
          })[0];

          if (defaut) {
            _this.LibelleFacture = defaut.libelle;
          }
        }

        _this.$emit('update:libelle-facture-selected', _this.LibelleFacture);
      }).catch(function (error) {
        _this.$message(error);
      });
    },
    onClear: function onClear() {
      this.$emit('update:libelle-facture-selected', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        var selection = this.LibelleFactureList.find(function (x) {
          return x.id === index;
        });
        this.$emit('update:libelle-facture-selected', selection.libelle);
      }
    }
  }
};
exports.default = _default;