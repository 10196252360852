"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAll() {
  return (0, _request.default)({
    url: '/sys_permissions',
    method: 'get'
  });
}