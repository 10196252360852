"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ListEnseigneMultiple',
  props: ['data', 'idsClients', 'idSociete'],
  data: function data() {
    return {
      enseignes: [],
      values: this.data
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.enseignes = [];
    var index = this.idsClients.indexOf(0);
    if (index !== -1) this.idsClients.splice(index, 1);

    if ((!this.idsClients || this.idsClients.length === 0) && this.idSociete > 0) {
      apiEnseigne.getAllBySocieteId(this.idSociete).then(function (results) {
        _this.enseignes = results;
      }).catch(function (error) {
        _this.$message(error);
      });
    } else {
      apiEnseigne.getAll().then(function (results) {
        _this.enseignes = results.filter(function (e) {
          return _this.idsClients.includes(e.id_client);
        });
      }).catch(function (error) {
        _this.$message(error);
      });
    }
  },
  methods: {
    onClear: function onClear() {
      this.values = []; // nb: onChange est appellé quand @clear est déclenché
    },
    onChange: function onChange(selection) {
      if (selection.includes(0)) {
        this.values = this.enseignes.map(function (x) {
          return x.id;
        });
      } else if (this.selection !== undefined) {
        this.values.push(this.selection);
      }

      this.$emit('update:data', this.values);
    }
  }
};
exports.default = _default;