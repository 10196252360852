"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _factures = require("@/helpers/factures");

var _utils = require("@/helpers/utils");

var _vuex = require("vuex");

var _LibelleFactureSelectList = _interopRequireDefault(require("@/components/LibelleFactureSelectList"));

var _CommandeSelectList = _interopRequireDefault(require("@/components/CommandeSelectList"));

var _CommandeInfos = _interopRequireDefault(require("@/components/CommandeInfos"));

var _TvaSelectList = _interopRequireDefault(require("@/components/TvaSelectList"));

var _TypeVenteSelectList = _interopRequireDefault(require("@/components/TypeVenteSelectList"));

var _MontantTypeSelectList = _interopRequireDefault(require("@/components/MontantTypeSelectList"));

var _constantes = require("@/helpers/constantes");

var apiFacture = _interopRequireWildcard(require("@/api/facture"));

var _moment = _interopRequireDefault(require("moment"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'FactureForm',
  components: {
    LibelleFactureSelectList: _LibelleFactureSelectList.default,
    CommandeSelectList: _CommandeSelectList.default,
    CommandeInfos: _CommandeInfos.default,
    TvaSelectList: _TvaSelectList.default,
    TypeVenteSelectList: _TypeVenteSelectList.default,
    MontantTypeSelectList: _MontantTypeSelectList.default
  },
  inject: ['getCommande'],
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  data: function data() {
    var _this = this;

    var validDateFacturation = function validDateFacturation(rule, value, callback) {
      var date = value;

      if (!(0, _moment.default)(date, 'YYYY/MM/DD').isValid()) {
        callback(new Error('Date de facturation invalide'));
      }

      callback();
    };

    var validDateEcheance = function validDateEcheance(rule, value, callback, source, options) {
      var dateEcheance = value;
      var dateFacture = _this.formData.date_facture || '';

      if (dateFacture && (0, _moment.default)(dateFacture, 'YYYY/MM/DD').isValid()) {
        if (!(0, _moment.default)(dateEcheance, 'YYYY/MM/DD').isValid()) {
          callback(new Error('Date d\'échéance invalide'));
        } else if (!(0, _moment.default)(dateEcheance, 'YYYY/MM/DD').isSameOrAfter(dateFacture)) {
          callback(new Error('La date d\'échéance ne peut être antérieure à la date de facturation'));
        }
      }

      callback();
    };
    /*
    const validRetenueGarantie = (rule, value, callback) => {
      const retenurGarantie = value
      if (retenurGarantie < 0) {
        callback(new Error('Le montant de la retenue de garantie doit être positif'))
      }
      callback()
    }
    */


    var validFichier = function validFichier(rule, value, callback, source, options) {
      if (_this.selectedFile) {
        var maxUploadSize = _constantes.FACTURES.MAX_UPLOAD_SIZE / 1024 / 1000; // converti en octets

        var fileSize = Number.parseFloat(_this.selectedFile.size / 1024 / 1000).toFixed(2); // converti en octets

        var allowedType = 'application/pdf';

        if (_this.selectedFile.type !== allowedType) {
          callback(new Error(_this.$i18n.t('facturation.facture.restricted_allowed_filetypes', {
            fileTypes: '.pdf'
          })));
        } else if (fileSize > maxUploadSize) {
          callback(new Error(_this.$i18n.t('facturation.facture.file_too_big', {
            fileSize: "".concat(fileSize, " Mo"),
            maxFileSize: "".concat(maxUploadSize, " Mo")
          })));
        }
      }

      callback();
    };

    var validNumero = function validNumero(rule, value, callback) {
      if (value !== '') {
        var payload = {
          id: _this.formData.id,
          numero: value
        };
        apiFacture.checkUnicity(payload).then(function (results) {
          if (results.length > 0) {
            callback(new Error('Numéro de facture déjà utilisé'));
          }

          callback();
        });
      }
    };

    var validLibFactureCode = function validLibFactureCode(rule, value, callback) {
      if (_this.isOldCodif()) return callback();

      if (!_this.libfacture_code) {
        return callback(new Error('champ obligatoire'));
      }

      callback();
    };

    var validLibFactureLibelle = function validLibFactureLibelle(rule, value, callback) {
      if (_this.isOldCodif()) return callback();

      if (_this.libfacture_libelle === '') {
        return callback(new Error('champ obligatoire'));
      }

      callback();
    };

    return {
      // Constantes
      CONST: {
        FACTURES: _constantes.FACTURES,
        TYPES_MONTANT: _constantes.TYPES_MONTANT,
        COEF_CALCUL_AUTO_RG: 0.05
      },
      autoCalculRG: false,
      selection: {},
      helper: {
        rev_actu: this.formData.rev_actu,
        rev_actu_type_montant: _constantes.TYPES_MONTANT.HT.value,
        retenue_garantie: this.formData.retenue_garantie,
        retenue_garantie_type_montant: _constantes.TYPES_MONTANT.TTC.value
      },
      // L'idCommande est-il forcé par la commande parente de la facture à créer / modifier ?
      idCommandeForced: false,
      // L'idCommande initialement lié à la facture actuelle
      idCommandeInitial: null,
      // Montant de la facture initial (0 en création mais égal au montantAPercevoirTtc en édition)
      montantFactureInitial: 0,
      // Doit-on supprimer le fichier précédemment uploadé ?
      deleteFichier: false,
      // Fichier à uploader
      selectedFile: null,
      // Règles de validation du formulaire
      rules: {
        date_facture: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validDateFacturation
        }],
        date_echeance: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validDateEcheance
        }],
        id_tva: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        id_type_vente: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        libfacture_code: [{
          validator: validLibFactureCode
        }],
        libfacture_libelle: [{
          validator: validLibFactureLibelle
        }],
        montant: [{
          required: true,
          message: 'Champ obligatoire'
        }],

        /*
        retenue_garantie: [
          { validator: validRetenueGarantie }
        ],
        */
        numero: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validNumero
        }],
        fichier: [{
          validator: validFichier
        }]
      },
      libfacture_nom: '',
      libfacture_code: '',
      libfacture_libelle: ''
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['affaire']), {}, (0, _vuex.mapGetters)('facturation', ['getTauxTvaById', 'getCommandeById']), {
    modeEdit: function modeEdit() {
      return this.action;
    },
    factureFichierUri: function factureFichierUri() {
      return (0, _factures.getFactureFichierUri)(this.formData);
    },
    // Une commande parente est-elle sélectionnée
    commandeIsSelected: function commandeIsSelected() {
      var idCommande = Number.parseInt(this.formData.id_commande);
      return !Number.isNaN(idCommande);
    },
    showMontantRevActuHt: function showMontantRevActuHt() {
      return (this.helper.rev_actu > 0 || this.helper.rev_actu < 0) && this.helper.rev_actu_type_montant === this.CONST.TYPES_MONTANT.TTC.value;
    },
    showMontantRetenueGarantieTtc: function showMontantRetenueGarantieTtc() {
      return this.helper.retenue_garantie > 0 && this.helper.retenue_garantie_type_montant === this.CONST.TYPES_MONTANT.HT.value;
    },
    // Définition: montant calculé en fonction du tarif HT ou TTC indiqué
    montantRevisionActualisation: function montantRevisionActualisation() {
      var montant = 0;

      if (this.helper.rev_actu > 0 || this.helper.rev_actu < 0) {
        switch (this.helper.rev_actu_type_montant) {
          // Saisie en HT
          case this.CONST.TYPES_MONTANT.HT.value:
            montant = this.helper.rev_actu;
            break;
          // Saisie en TTC

          case this.CONST.TYPES_MONTANT.TTC.value:
            montant = this.helper.rev_actu / (1 + this.tauxTva);
            break;
        }
      }

      return Number.parseFloat(montant);
    },
    // Définition: {Montant HT} + {Révision / Actualisation}
    // Calcul: `montant_ht` + `rev_actu`
    montantTotalHt: function montantTotalHt() {
      var montant = Number.isNaN(Number.parseFloat(this.formData.montant)) ? 0 : Number.parseFloat(this.formData.montant);
      var revisionActualisation = this.montantRevisionActualisation; // const revisionActualisation = Number.isNaN(Number.parseFloat(this.formData.rev_actu)) ? 0 : Number.parseFloat(this.formData.rev_actu)

      var montantTotalHT = montant + revisionActualisation;
      return montantTotalHT;
    },
    // Taux de TVA en pourcentage
    tauxTva: function tauxTva() {
      var idTVA = Number.parseInt(this.formData.id_tva);

      if (Number.isNaN(idTVA)) {
        return 0;
      } // On utilise le store pour trouver le taux de TVA correspondant à la TVA sélectionnée


      var tva = this.getTauxTvaById(idTVA);
      return tva.taux / 100;
    },
    // Définition: {Montant Total HT} * {Taux TVA}%
    // Calcul: `montantTotalHt` + `tva.taux` / 100
    montantTotalTva: function montantTotalTva() {
      return this.montantTotalHt * this.tauxTva;
    },
    // Définition: {Montant Total HT} * {Montant total TVA}
    // Calcul: `montantTotalHt` + `montantTotalTva`
    montantAvantRetenueGarantieTtc: function montantAvantRetenueGarantieTtc() {
      return this.montantTotalHt + this.montantTotalTva;
    },
    // Définition: montant calculé en fonction du tarif HT ou TTC indiqué
    montantRetenueGarantie: function montantRetenueGarantie() {
      var montant = 0;
      var tarif = Number.isNaN(Number.parseFloat(this.helper.retenue_garantie)) ? 0 : Number.parseFloat(this.helper.retenue_garantie);
      var tarifType = this.helper.retenue_garantie_type_montant;

      if (tarif !== 0) {
        switch (tarifType) {
          // Saisie en HT
          case this.CONST.TYPES_MONTANT.HT.value:
            montant = tarif * (1 + this.tauxTva);
            break;
          // Saisie en TTC

          case this.CONST.TYPES_MONTANT.TTC.value:
            montant = tarif;
            break;
        }
      }

      return Number.parseFloat(montant);
    },
    // Définition: {Montant Total TTC avant R.G.} - {Retenue de Garantie TTC} + {Divers TTC}
    // Calcul: `montantAvantRetenueGarantieTtc` - `retenue_garantie` + `divers_ttc`
    montantApresRetenueGarantieTtc: function montantApresRetenueGarantieTtc() {
      var retenueGarantie = this.montantRetenueGarantie;
      var diversTTC = Number.isNaN(Number.parseFloat(this.formData.divers_ttc)) ? 0 : Number.parseFloat(this.formData.divers_ttc);
      var montantApresRetenueGarantieTtc = this.montantAvantRetenueGarantieTtc - retenueGarantie + diversTTC;
      return montantApresRetenueGarantieTtc;
    },
    // Définition: {Montant Total TTC après R.G.} - {Montant Paiement Direct Sous-Traitant}
    // Calcul: `montantApresRetenueGarantieTtc` - `mt_paiement_direct_st`
    montantAPercevoirTtc: function montantAPercevoirTtc() {
      var montantPaiementDirectSousTraitant = Number.isNaN(Number.parseFloat(this.formData.mt_paiement_direct_st)) ? 0 : Number.parseFloat(this.formData.mt_paiement_direct_st);
      var montantAPercevoirTtc = this.montantApresRetenueGarantieTtc - montantPaiementDirectSousTraitant;
      return montantAPercevoirTtc;
    },
    montantFacture: function montantFacture() {
      var montant = Number(this.montantTotalHt);
      return isNaN(montant) ? 0 : montant;
    },
    echeanceByPaymentDelay: function echeanceByPaymentDelay() {
      return this.affaire.affaire.delai_paiement && this.formData.date_echeance === this.getDateEcheance();
    }
  }),
  watch: {
    /* ---------------------------------------
     *  Montant de la facture initial
     * ---------------------------------------
     *
     *   - si on est en édition
     *     ET que la facture était initialement liée à la commande sélectionnée
     *     le montant initial est égal au montantAPercevoirTtc
     *   - par défaut: 0.00
     *
     */
    'formData.mt_a_percevoir_ttc': function formDataMt_a_percevoir_ttc(newVal) {
      this.setMontantFactureInitial();
    },
    'formData.id_commande': function formDataId_commande(newVal) {
      this.setEmetteurCommande();
    },
    'formData.date_facture': function formDataDate_facture(newVal) {
      this.setDateEcheance();
    },
    'selection.commande': function selectionCommande(newVal) {
      this.setMontantFactureInitial();
    },
    'helper.rev_actu': function helperRev_actu(newVal) {
      this.setMontantRevActuHt();
    },
    'helper.rev_actu_type_montant': function helperRev_actu_type_montant(newVal) {
      this.setMontantRevActuHt();
    },
    'helper.retenue_garantie': function helperRetenue_garantie(newVal) {
      this.setMontantRetenueGarantieTtc();

      if (Number.isNaN(Number.parseFloat(newVal))) {
        this.autoCalculRG = false;
      }
    },
    'helper.retenue_garantie_type_montant': function helperRetenue_garantie_type_montant(newVal) {
      this.setMontantRetenueGarantieTtc();
    },
    'formData.id_tva': function formDataId_tva(newVal) {
      this.setMontantRevActuHt();
      this.setMontantRetenueGarantieTtc();
    },
    'autoCalculRG': function autoCalculRG(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.calculateRG();
      } else if (newVal === false && oldVal === true) {
        this.helper.retenue_garantie = '';
      }
    },
    'montantAvantRetenueGarantieTtc': function montantAvantRetenueGarantieTtc(newVal) {
      if (this.autoCalculRG) {
        this.calculateRG();
      }
    }
  },
  created: function created() {
    var vm = this; // Récupération de l'ID de la commande parente sélectionnée
    // pour lier automatiquement la facture à cette commande

    vm.getCommande(function (commande) {
      if (commande) {
        vm.idCommandeForced = true;
        vm.formData.id_commande = commande.id;
        vm.formData.emetteur = commande.emetteur;
        vm.formData.libelle = commande.libelle;
      }
    });
  },
  beforeMount: function beforeMount() {
    this.idCommandeInitial = this.formData.id_commande;
    /*
    this.$watch(
      () => this.$refs.LibelleFactureList?.LibelleFactureList,
      (newVal, oldVal) => {
        console.log('facture avec ancienne codification du libellé de facture :', this.isOldCodif(), '|', this.formData.libelle)
        if (this.isOldCodif()) { // pour gérer le format des anciens libellés sans les codes ['SIT', 'AV', 'DGD', 'FACT', ...]
          this.libfacture_nom = this.nomAffaire()
          this.libfacture_code = ''
          this.libfacture_libelle = this.formData.libelle || ''
        } else { // on decoupe le libellé pour initialiser les zones écrans nom/code/libelle de la comande
          let nom = this.nomAffaire() // recherche du nom de l'affaire à partir du num_affaire
          let code = ''
          let libelle = this.formData.libelle || ''
          if (this.formData.libelle !== '') {
            const libSansNom = libelle.replace(nom, '').trim()
            code = this.extractFirstPart(libSansNom).trim()
            libelle = this.extractLastPart(libSansNom, ' ', 1).trim()
             const list = newVal.map(x => x.libelle)
            code = list.includes(code) ? code : ''
          }
          this.libfacture_nom = nom
          this.libfacture_code = code
          this.libfacture_libelle = libelle
        }
      }
    )
    */

    console.log('facture avec ancienne codification du libellé de facture :', this.isOldCodif(), '|', this.formData.libelle);

    if (this.isOldCodif()) {
      // pour gérer le format des anciens libellés sans les codes ['SIT', 'AV', 'DGD', 'FACT', ...]
      this.libfacture_nom = this.nomAffaire();
      this.libfacture_code = '';
      this.libfacture_libelle = this.formData.libelle || '';
    } else {
      // on decoupe le libellé pour initialiser les zones écrans nom/code/libelle de la comande
      var nom = this.nomAffaire(); // recherche du nom de l'affaire à partir du num_affaire

      var code = '';
      var libelle = this.formData.libelle || '';

      if (this.formData.libelle !== '') {
        var libSansNom = libelle.replace(nom, '').trim();
        code = this.extractFirstPart(libSansNom).trim();
        libelle = this.extractLastPart(libSansNom, ' ', 1).trim();
      }

      this.libfacture_nom = nom;
      this.libfacture_code = code;
      this.libfacture_libelle = libelle;
    }
  },
  methods: _objectSpread({}, (0, _vuex.mapActions)('facturation', ['insertFacture', 'updateFacture']), {
    // =================================================================
    // Pour la gestion du libellé de la facture
    // =================================================================
    isOldCodif: function isOldCodif() {
      var nom = this.nomAffaire();
      var libelle = this.formData.libelle || '';
      return libelle !== '' && !libelle.startsWith(nom);
    },
    // retourne le nom de l'affaire à partir du num_affaire
    nomAffaire: function nomAffaire() {
      switch (this.affaire.affaire.id_societe) {
        case 1:
          // PHM IMMO
          return this.extractLastPart(this.affaire.affaire.num_affaire, '-', 2);

        case 2:
          // CLUB SA
          return this.extractLastPart(this.affaire.affaire.num_affaire, '-', 2);

        case 3:
          // VISION HABITAT
          return this.extractLastPart(this.affaire.affaire.num_affaire, ' ', 1);

        case 4:
          // PHM INVEST
          return this.extractLastPart(this.affaire.affaire.num_affaire, '-', 1);
      }
    },
    libelleFacture: function libelleFacture() {
      var list = this.$refs.LibelleFactureList.LibelleFactureList.map(function (x) {
        return x.libelle;
      });
      var libelle = this.libfacture_libelle;

      if (list.includes(this.libfacture_code)) {
        libelle = "".concat(this.libfacture_nom, " ").concat(this.libfacture_code, " ").concat(this.libfacture_libelle);
      } // pas plus de 50 caractères. la taille le champ libelle de la table commande = 50 cars


      return libelle.substring(0, 50);
    },
    // retourne la première partie d'une chaîne, le séparateur = espace
    extractFirstPart: function extractFirstPart(input) {
      var index = input.indexOf(' ');

      if (index === -1) {
        return input;
      }

      return input.substring(0, index);
    },
    // retourne la fin d'une chaine à partir de l'occurence d'un séparateur
    extractLastPart: function extractLastPart(input, separator) {
      var separatorOccurrence = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      // Trouver l'index du séparateur en fonction de l'occurrence spécifiée
      var index = -1;
      var count = 0;

      for (var i = 0; i < input.length; i++) {
        if (input[i] === separator) {
          count++;

          if (count === separatorOccurrence) {
            index = i;
            break;
          }
        }
      }

      if (index === -1) {
        // Si le séparateur n'est pas trouvé
        return input;
      } // Retourner la partie après le séparateur


      return input.substring(index + 1);
    },
    // ===== (fin pour la gestion du libellé de la facture) ======
    getCalculatedRGAmount: function getCalculatedRGAmount() {
      var coef = this.CONST.COEF_CALCUL_AUTO_RG; // Récupérer le montant TTC avant RG

      var montantAvantRetenueGarantieTtc = Number.parseFloat(this.montantAvantRetenueGarantieTtc);

      if (!Number.isNaN(montantAvantRetenueGarantieTtc)) {
        return Math.round(coef * montantAvantRetenueGarantieTtc * 100) / 100;
      }

      return false;
    },
    calculateRG: function calculateRG() {
      /*
      const coef = this.CONST.COEF_CALCUL_AUTO_RG
      // Récupérer le montant TTC avant RG
      const montantAvantRetenueGarantieTtc = Number.parseFloat(this.montantAvantRetenueGarantieTtc)
      if (!Number.isNaN(montantAvantRetenueGarantieTtc)) {
        this.helper.retenue_garantie = Math.round(coef * montantAvantRetenueGarantieTtc * 100) / 100
      }
      */
      var montant = this.getCalculatedRGAmount();

      if (montant !== false) {
        this.helper.retenue_garantie = montant;
      }
    },
    // Si une commande est sélectionnée : remplir le champ `emetteur` avec l'émetteur de la commande parente
    // sinon vider le champ pour que l'utilisateur puisse le remplir
    setEmetteurCommande: function setEmetteurCommande() {
      // Récupérer l'émetteur de la commande parente (si sélectionnée)
      var idCommande = Number.parseInt(this.formData.id_commande);

      if (Number.isNaN(idCommande)) {
        this.formData.emetteur = '';
        return;
      } // On utilise le store pour trouver l'émetteur de la commande correspondant à la commande sélectionnée


      var commande = this.getCommandeById(idCommande);
      this.formData.emetteur = commande.emetteur;
    },
    // Calculer la date d'échéance d'après la date de facture et le délai de paiement de l'affaire
    getDateEcheance: function getDateEcheance() {
      var dateFacture = this.formData.date_facture || '';
      var delaiPaiement = this.affaire.affaire.delai_paiement || '';
      var dateEcheance = (0, _utils.getDateEcheanceByDelaiPaiement)({
        dateFacture: dateFacture,
        delaiPaiement: delaiPaiement
      });

      if (!(0, _moment.default)(dateEcheance, 'YYYY/MM/DD').isValid()) {
        dateEcheance = null;
      }

      return dateEcheance;
    },
    setDateEcheance: function setDateEcheance() {
      this.formData.date_echeance = this.getDateEcheance();
    },
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;

            case 'edit':
              _this2.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      this.$loader(true);
      var payload = {
        facture: this.getFormData(),
        fichier: {
          newFichier: this.selectedFile,
          deleteFichier: this.deleteFichier
        }
      }; // 1. Création de la facture en BDD

      this.insertFacture(payload).then(function (_) {
        // 2 Facture créée en BDD et fichier uploadé si nécessaire
        _this3.$loader(false);

        _this3.$message('Création effectuée avec succès');

        _this3.resetForm(); // console.log('[Facture/actionNew] 3 emit event FACTURE_SAVE')


        _eventBus.EventBus.$emit('FACTURE_SAVE');
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      this.$loader(true);
      var payload = {
        facture: _objectSpread({}, this.getFormData(), {
          id: this.formData.id
        }),
        fichier: {
          newFichier: this.selectedFile,
          deleteFichier: this.deleteFichier
        }
      }; // 1. Modification de la facture en BDD

      this.updateFacture(payload).then(function (_) {
        // 2 Facture créée en BDD et fichier uploadé si nécessaire
        _this4.$loader(false);

        _this4.$message('Mise à jour effectuée avec succès');

        _this4.resetForm();

        _eventBus.EventBus.$emit('FACTURE_SAVE');
      }).catch(function (error) {
        _this4.$loader(false);

        _this4.$message(error);
      });
    },
    getFormData: function getFormData() {
      return {
        id_commande: this.formData.id_commande,
        id_tva: this.formData.id_tva,
        id_type_vente: this.formData.id_type_vente,
        id_affaire: this.affaire.affaire.id,
        numero: this.formData.numero,
        date_facture: this.formData.date_facture,
        date_echeance: this.formData.date_echeance,
        montant: Number.isNaN(Number.parseFloat(this.formData.montant)) ? 0 : Number.parseFloat(this.formData.montant),
        // Revision / Actualisation HT (calculée en fonction de la saisie HT ou TTC de l'utilisateur)
        rev_actu: Number.isNaN(Number.parseFloat(this.formData.rev_actu)) ? 0 : Number.parseFloat(this.formData.rev_actu),
        // Retenue de Garantie TTC (calculée en fonction de la saisie HT ou TTC de l'utilisateur)
        retenue_garantie: Number.isNaN(Number.parseFloat(this.formData.retenue_garantie)) ? 0 : Number.parseFloat(this.formData.retenue_garantie),
        divers_ttc: Number.isNaN(Number.parseFloat(this.formData.divers_ttc)) ? 0 : Number.parseFloat(this.formData.divers_ttc),
        // libelle: this.formData.libelle,
        libelle: this.libelleFacture(),
        /// nouveau format du libellé de la facture pour une bonne intégration dans SAGE 1000 (evol 02/2024)
        emetteur: this.formData.emetteur,
        mt_paiement_direct_st: Number.isNaN(Number.parseFloat(this.formData.mt_paiement_direct_st)) ? 0 : Number.parseFloat(this.formData.mt_paiement_direct_st),
        // Champ montantAPercevoirTtc calculé
        mt_a_percevoir_ttc: Number.isNaN(Number.parseFloat(this.montantAPercevoirTtc)) ? 0 : Number.parseFloat(this.montantAPercevoirTtc),
        // le fichier sera ensuite mis à jour via la fonctionnalité d'upload
        fichier: this.formData.fichier
      };
    },
    onFileChange: function onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    onFileRemove: function onFileRemove() {
      this.selectedFile = null;
      this.$refs.fichierUploader.value = '';
    },
    setMontantFactureInitial: function setMontantFactureInitial() {
      if (this.formData.id_commande !== null && this.selection.commande && this.idCommandeInitial === this.selection.commande.id) {
        this.montantFactureInitial = this.montantTotalHt;
      } else {
        this.montantFactureInitial = 0;
      }
    },
    setMontantRevActuHt: function setMontantRevActuHt() {
      this.formData.rev_actu = this.montantRevisionActualisation;
    },
    setMontantRetenueGarantieTtc: function setMontantRetenueGarantieTtc() {
      this.formData.retenue_garantie = this.montantRetenueGarantie;
    }
  })
};
exports.default = _default;