"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiTva = _interopRequireWildcard(require("@/api/tva"));

var _utils = require("@/helpers/utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TvaSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idTvaSelected: {
      type: Number
    },
    tva: {
      type: Object
    },
    showLibelleCompte: {
      type: Boolean,
      // permet d'afficher/masquer le n° de compte dans la liste déroulante, 0=false - 1=true
      default: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      tvas: [],
      idTva: this.idTvaSelected
    };
  },
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.getAll();
  },
  computed: {
    dspLibelleCompte: function dspLibelleCompte() {
      return this.showLibelleCompte;
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    getAll: function getAll() {
      var _this = this;

      apiTva.getAll().then(function (results) {
        _this.tvas = results;

        var tva = _this.tvas.find(function (x) {
          return x.id === _this.idTvaSelected;
        });

        if (tva) {
          _this.$emit('update:tva', tva);
        }
      }).catch(function (error) {
        _this.$message(error);
      });
    },
    onClear: function onClear() {
      this.$emit('update:id-tva-selected', null);
      this.$emit('update:tva', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-tva-selected', index);
        this.$emit('update:tva', this.tvas.find(function (x) {
          return x.id === index;
        }));
      }
    },
    toPercentage: function toPercentage(taux) {
      return (0, _utils.toPercentage)(taux);
    }
  } // 11. Rendu
  // --

};
exports.default = _default;