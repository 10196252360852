"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiWorkflow = _interopRequireWildcard(require("@/api/workflow"));

var _vuex = require("vuex");

var _listEtape = _interopRequireDefault(require("@/components/listEtape"));

var _listSecteurAct = _interopRequireDefault(require("@/components/listSecteurAct"));

var _listUserProfil = _interopRequireDefault(require("@/components/listUserProfil"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  components: {
    listEtape: _listEtape.default,
    listSecteurAct: _listSecteurAct.default,
    listUserProfil: _listUserProfil.default
  },
  data: function data() {
    return {
      selection: [],
      rules: {
        etape: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        key_role: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        niveau: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      },
      niveaux: [{
        value: 0,
        label: 'Saisie'
      }, {
        value: 1,
        label: 'Validation niveau 1'
      }, {
        value: 2,
        label: 'Validation niveau 2'
      }]
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['user']), {
    idSociete: function idSociete() {
      return this.user.id_societe;
    },
    societeName: function societeName() {
      return this.user.societe_name;
    }
  }),
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiWorkflow.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiWorkflow.update(this.formData.id, data).then(function (results) {
        _this3.$message('Mise à jour effectuée avec succès');

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    getFormData: function getFormData() {
      return {
        id_societe: this.idSociete,
        etape: this.formData.etape,
        id_secteur_act: this.formData.id_secteur_act,
        key_role: this.formData.key_role,
        niveau: this.formData.niveau
      };
    }
  }
};
exports.default = _default;