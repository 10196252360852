"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CommandeSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idCommandeSelected: {
      type: Number
    },
    commande: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      commandes: [],
      idCommande: this.idCommandeSelected
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)('facturation', ['getCommandeListForCurrentAffaire'])),
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    var _this = this;

    this.commandes = this.getCommandeListForCurrentAffaire;
    var commande = this.commandes.find(function (x) {
      return x.id === _this.idCommandeSelected;
    });

    if (commande) {
      this.$emit('update:commande', commande);
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    onClear: function onClear() {
      this.$emit('update:id-commande-selected', null);
      this.$emit('update:commande', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-commande-selected', index);
        this.$emit('update:commande', this.commandes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;