"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'dlgNewLineAvenant',
  props: ['idLot', 'ordre', 'visible'],
  data: function data() {
    return {
      formLabelWidth: '120px',
      formData: {},
      rules: {
        libelle: {
          required: true,
          message: 'Champ obligatoire'
        },
        prestataire: {
          required: true,
          message: 'Champ obligatoire'
        }
      }
    };
  },
  methods: {
    handleOpened: function handleOpened() {
      this.formData = {};
      this.$refs['ElForm'].resetFields();
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    handleAdd: function handleAdd() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          var lotdetail = {
            id_lot: _this.idLot,
            type_lot_detail: CONST.LOT_DETAIL.TYPE_LIGNE.AVENANT_MARCHE_PRINCIPAL,
            ordre: _this.ordre + 1 || 1,
            libelle: _this.formData.libelle,
            prestataire: _this.formData.prestataire
          };

          _this.$store.dispatch('affaire/insertLotDetail', lotdetail).then(function () {
            _this.$store.dispatch('affaire/setHistorique', _objectSpread({}, _this.$store.getters.affaire.affaire, {
              message: 'Nouvelle ligne d\'avenant au marché principal: ' + lotdetail.libelle.trim() + '/' + lotdetail.prestataire.trim()
            }));

            _this.$store.dispatch('affaire/setLotDetail', lotdetail).then(function () {
              _this.handleClose();

              _eventBus.EventBus.$emit('REFRESH_CALCULS');
            });
          }).catch(function (error) {
            _this.$message(error);
          });
        }
      });
    }
  }
};
exports.default = _default;