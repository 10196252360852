"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiSysPermissions = _interopRequireWildcard(require("@/api/sys_permissions"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ListPermissions',
  props: ['rules'],
  data: function data() {
    return {
      data: [],
      list: [],
      selection: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.list = [];

    if (this.rules) {
      var rules = JSON.parse(this.rules);

      if (rules) {
        Object.keys(rules).map(function (key) {
          _this.list[key] = rules[key].split(',');
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;

    apiSysPermissions.getAll().then(function (results) {
      _this2.data = results;
    }).catch(function (error) {
      _this2.$message(error);
    });
  },
  methods: {
    itemList: function itemList(key) {
      var array = this.data.find(function (x) {
        return x.key === key;
      });

      if (array.rules) {
        return array.rules.split(',');
      } else {
        return [];
      }
    },
    onChange: function onChange(keyname, row) {
      var _this3 = this;

      var selection = {};
      Object.keys(this.list).map(function (key) {
        var rules = _this3.list[key].join(',');

        if (rules) {
          selection[key] = rules;
        }
      });
      this.$emit('update:rules', JSON.stringify(selection));
    }
  }
};
exports.default = _default;