"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _eventBus = require("@/helpers/event-bus.js");

var apiAffaire = _interopRequireWildcard(require("@/api/affaire"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'InfosAffaireBottom',
  data: function data() {
    return {
      CONST: CONST,
      spanCol1: '5',
      spanCol2: '19',
      editDateOuvertureChantier: false,
      editDateReception: false,
      formData: {
        date_ouverture_chantier: null,
        date_reception: null
      },
      refresh: 0,
      rules: {}
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.refresh++;
    });
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['user']), {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  }),
  methods: {
    canEdit: function canEdit() {
      var cond1 = this.affaire.YYYYMM === this.affaire.histoLastGestMens && this.affaire.histoLastGestMens && this.affaire.YYYYMM === this.affaire.lastGestMens;
      var cond2 = [CONST.AFFAIRE.STATUS.CLOSED.value, CONST.AFFAIRE.STATUS.SLEEPING.value, CONST.AFFAIRE.STATUS.ARCHIVED.value].includes(this.affaire.affaire.status);
      return this.affaire.YYYYMM ? cond1 && !cond2 : true;
    },
    handleChangeDateOuvertureChantier: function handleChangeDateOuvertureChantier() {
      this.editDateOuvertureChantier = true;
    },
    handleCancelDateOuvertureChantier: function handleCancelDateOuvertureChantier() {
      this.editDateOuvertureChantier = false;
    },
    handleChangeDateReception: function handleChangeDateReception() {
      this.editDateReception = true;
    },
    handleCancelDateReception: function handleCancelDateReception() {
      this.editDateReception = false;
    },
    handleSaveDateOuvertureChantier: function handleSaveDateOuvertureChantier() {
      var _this2 = this;

      this.$loader(true);
      apiAffaire.update(this.affaire.affaire.id, {
        date_ouverture_chantier: (0, _utils.dateToSQL)(this.formData.date_ouverture_chantier)
      }).then(function (results) {
        _this2.editDateOuvertureChantier = false;

        _this2.$store.dispatch('affaire/getAffaireById', _this2.affaire.affaire.id).then(function () {
          _this2.$store.dispatch('affaire/setHistorique', _objectSpread({}, _this2.affaire.affaire, {
            message: 'Nouvelle date d\'ouverture de chantier: ' + _this2.formData.date_ouverture_chantier
          }));

          _this2.$loader(false);
        });
      }).catch(function (error) {
        _this2.$loader(false);

        _this2.$message(error);
      });
    },
    handleSaveDateReception: function handleSaveDateReception() {
      var _this3 = this;

      this.$loader(true);
      apiAffaire.update(this.affaire.affaire.id, {
        date_reception: (0, _utils.dateToSQL)(this.formData.date_reception)
      }).then(function (results) {
        _this3.editDateReception = false;

        _this3.$store.dispatch('affaire/getAffaireById', _this3.affaire.affaire.id).then(function () {
          _this3.$store.dispatch('affaire/setHistorique', _objectSpread({}, _this3.affaire.affaire, {
            message: 'Nouvelle date de réception: ' + _this3.formData.date_reception
          }));

          _this3.$loader(false);
        });
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    }
  }
};
exports.default = _default;