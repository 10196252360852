"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendMail = sendMail;
exports.newUser = newUser;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function sendMail(data) {
  return (0, _request.default)({
    url: '/email/notification',
    method: 'post',
    data: data
  });
}

function newUser(data) {
  return (0, _request.default)({
    url: '/email/newuser',
    method: 'post',
    data: data
  });
}