"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Enseigne',
  // props: ['idEnseigne', 'idClient', 'enseigne'],
  props: {
    idEnseigne: {
      type: Number,
      required: false,
      default: null
    },
    idClient: {
      type: Number,
      required: false,
      default: null
    },
    enseigne: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      enseignes: [],
      id_enseigne: this.idEnseigne
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    if (this.idClient === null) {
      this.enseignes = [];
      return;
    }

    apiEnseigne.getAllActifByIdClient(this.idClient).then(function (results) {
      _this.enseignes = results;

      var enseigne = _this.enseignes.find(function (x) {
        return x.id === _this.idEnseigne;
      });

      if (enseigne) {
        _this.$emit('update:enseigne', enseigne);
      }
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  computed: {
    listEnseignes: function listEnseignes() {
      var _this2 = this;

      if (this.idClient > 0) {
        return this.enseignes.filter(function (x) {
          return x.id_client === _this2.idClient;
        });
      } else {
        return this.enseignes;
      }
    }
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:idEnseigne', null);
      this.$emit('update:enseigne', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:idEnseigne', index);
        this.$emit('update:enseigne', this.enseignes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  },
  watch: {
    idClient: function idClient() {
      this.$emit('update:idEnseigne', null);
      this.$emit('update:enseigne', null);
    }
  }
};
exports.default = _default;