"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _TypeVenteForm = _interopRequireDefault(require("./components/TypeVenteForm"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TypeVenteNew',
  components: {
    TypeVenteForm: _TypeVenteForm.default
  },
  data: function data() {
    return {
      action: 'new',
      formData: {},
      title: 'Ajouter un type de vente'
    };
  }
};
exports.default = _default;