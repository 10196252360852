"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var routes = [{
  path: '/reports/synthese',
  name: 'Rapport-Synthese',
  order: 1,
  meta: {
    title: 'Synthèse de gestion',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/synthese'));
    });
  }
}, {
  path: '/reports/recap_annuel',
  name: 'Rapport-RecapAnnuel',
  order: 2,
  meta: {
    title: 'Récap. annuel Synthèse de gestion',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/recap_annuel'));
    });
  }
}, {
  path: '/reports/fae_pca',
  name: 'Rapport-FaePca',
  order: 3,
  meta: {
    title: 'FAE PCA',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/fae_pca'));
    });
  }
}, {
  path: '/reports/debourses_indirects',
  name: 'Rapport-DeboursesIndirects',
  order: 4,
  meta: {
    title: 'Déboursés Indirects',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/debourses_indirects'));
    });
  }
}, {
  path: '/reports/arrets_comptables_di',
  name: 'Rapport-ArretsComptablesDI',
  order: 5,
  meta: {
    title: 'Arrêtés comptables DI',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/arrets_comptables_di'));
    });
  }
}, {
  path: '/reports/aleas',
  name: 'Rapport-Aleas',
  order: 6,
  meta: {
    title: 'Aléas',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/aleas'));
    });
  }
}, {
  path: '/reports/engages',
  name: 'Rapport-Engages',
  order: 7,
  meta: {
    title: 'Engagés',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/engages'));
    });
  }
}, {
  path: '/reports/provisions',
  name: 'Rapport-Provisions',
  order: 8,
  meta: {
    title: 'Provisions',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/reports/provisions'));
    });
  }
}];
var _default = routes;
exports.default = _default;