"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _LibelleFactureForm = _interopRequireDefault(require("./components/LibelleFactureForm"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LibelleFactureNew',
  components: {
    LibelleFactureForm: _LibelleFactureForm.default
  },
  data: function data() {
    return {
      action: 'new',
      formData: {},
      title: 'Ajouter un libellé de facture'
    };
  }
};
exports.default = _default;