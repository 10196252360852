"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventBus = void 0;

var _vue = _interopRequireDefault(require("vue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EventBus = new _vue.default();
/*

import { EventBus } from '@/helpers/event-bus.js'

mounted () {
  EventBus.$on('MY_EVENT', () => {
    ...
  })
}

beforeDestroy () {
  EventBus.$off('MY_EVENT')
}

EventBus.$emit('MY_EVENT', value)

*/

exports.EventBus = EventBus;