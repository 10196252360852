"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var apiSecteurGeo = _interopRequireWildcard(require("@/api/secteurgeo"));

var _listCascaderUtilisateur = _interopRequireDefault(require("@/components/listCascaderUtilisateur"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  components: {
    listCascaderUtilisateur: _listCascaderUtilisateur.default
  },
  data: function data() {
    var _this = this;

    var validRequired = function validRequired(rule, value, callback) {
      value = value || '';
      var required = rule.key && _this.workflow[rule.key] && _this.workflow[rule.key].required ? _this.workflow[rule.key].required : false;

      if (value === '' && required) {
        callback(new Error('Champ obligatoire'));
      }

      callback();
    };

    return {
      selection: [],
      rules: {
        libelle: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        code: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        id_responsable_secteur: [{
          key: 'responsableSecteur',
          validator: validRequired
        }]
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['workflow'])),
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;

            case 'edit':
              _this2.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiSecteurGeo.insert(data).then(function (results) {
        _this3.$message('Création effectuée avec succès');

        _this3.resetForm();

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiSecteurGeo.update(this.formData.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');

        _this4.$loader(false);
      }).catch(function (error) {
        _this4.$message(error);

        _this4.$loader(false);
      });
    },
    getFormData: function getFormData() {
      var data = {
        id_societe: this.$store.getters.user.id_societe,
        libelle: this.formData.libelle,
        code: this.formData.code,
        id_responsable_secteur: this.formData.id_responsable_secteur,
        actif: this.formData.actif
      };
      return data;
    }
  }
};
exports.default = _default;