"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var apiApp = _interopRequireWildcard(require("@/api/app"));

var apiSysParams = _interopRequireWildcard(require("@/api/sys_params"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  activeMenu: '',
  breadcrumb: '',
  gitInfo: {
    api: {
      lastTagVersion: 'vx.x.x',
      lastCommitHash: 'xxxxxx'
    },
    app: {
      lastTagVersion: 'vx.x.x',
      lastCommitHash: 'xxxxxx'
    }
  },
  messageNavbar: '',
  envNode: process.env.NODE_ENV // development, staging, production

};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);

    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  ACTIVE_MENU: function ACTIVE_MENU(state, menu) {
    state.activeMenu = menu;
  },
  TAB_MENU: function TAB_MENU(state, text) {
    state.breadcrumb = text;
  },
  SET_GIT_INFO: function SET_GIT_INFO(state, info) {
    state.gitInfo = info;
  },
  SET_MESSAGE_NAVBAR: function SET_MESSAGE_NAVBAR(state, message) {
    state.messageNavbar = message;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  activeMenu: function activeMenu(_ref5, menu) {
    var commit = _ref5.commit;
    commit('ACTIVE_MENU', menu);
  },
  tabmenu: function tabmenu(_ref6, text) {
    var commit = _ref6.commit;
    commit('TAB_MENU', text);
  },

  /* ---------------------------------------
   *  Informations sur la branche de dépôt actuelle (API et APP)
   * ---------------------------------------
   *  - récupération du dernier tag
   *  - récupération du hash du dernier commit
   */
  getGitInfo: function getGitInfo(_ref7) {
    var commit = _ref7.commit,
        state = _ref7.state,
        dispatch = _ref7.dispatch;
    return apiApp.getVersion().then(function (results) {
      commit('SET_GIT_INFO', results);
    });
  },
  messageNavbar: function messageNavbar(_ref8, message) {
    var commit = _ref8.commit;
    return apiSysParams.getAll().then(function (results) {
      if (results) {
        var _message = results.find(function (x) {
          return x.code === 'MESSAGE_UTILISATEUR';
        });

        if (_message) {
          commit('SET_MESSAGE_NAVBAR', _message.valeur);
        }
      }
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;