"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiSecteurAct = _interopRequireWildcard(require("@/api/secteuract"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  data: function data() {
    return {
      rules: {
        libelle: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        code: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiSecteurAct.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiSecteurAct.update(this.formData.id, data).then(function (results) {
        _this3.$message('Mise à jour effectuée avec succès');

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    getFormData: function getFormData() {
      var data = {
        id_societe: this.$store.getters.user.id_societe,
        libelle: this.formData.libelle.trim(),
        code: this.formData.code.trim(),
        actif: this.formData.actif
      };
      return data;
    }
  }
};
exports.default = _default;