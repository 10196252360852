"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = {
  name: 'LotDetailBudAch',
  props: ['idLot'],
  data: function data() {
    return {
      multipleSelection: [],
      refresh: 0,
      lotsDetail: [],
      calculs: [],
      totaux: [],
      modeEdit: false,
      errCheckLibelle: false,
      errCheckPrestataire: false,
      rowsForCanceling: [],
      rowsForSaving: [],
      // pour mémoriser uniquement les lignes modifiées et éviter de tout sauvegarder,
      // --
      CONST: CONST
    };
  },
  computed: {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    },
    canEdit: function canEdit() {
      var cond1 = [CONST.AFFAIRE.STATUS.CREATED.value, CONST.AFFAIRE.STATUS.TOVALIDATE1.value, CONST.AFFAIRE.STATUS.VALIDATED1.value, CONST.AFFAIRE.STATUS.TOVALIDATE2.value, CONST.AFFAIRE.STATUS.VALIDATED2.value, CONST.AFFAIRE.STATUS.DENIED1.value, CONST.AFFAIRE.STATUS.DENIED2.value].includes(this.affaire.affaire.status) && CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value === this.affaire.affaire.etape;
      var cond2 = [CONST.AFFAIRE.STATUS.CREATED.value, CONST.AFFAIRE.STATUS.TOVALIDATE1.value, CONST.AFFAIRE.STATUS.VALIDATED1.value, CONST.AFFAIRE.STATUS.TOVALIDATE2.value, CONST.AFFAIRE.STATUS.VALIDATED2.value, CONST.AFFAIRE.STATUS.DENIED1.value, CONST.AFFAIRE.STATUS.DENIED2.value].includes(this.affaire.affaire.statusBudgetAffaire) && CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value === this.affaire.affaire.etape;
      return cond1 || cond2;
    }
  },
  created: function created() {
    var _this = this;

    this.$watch('$root.modeEdit', function (newValue) {
      if (newValue === false) {
        _this.modeEdit = false;
      }
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    _eventBus.EventBus.$on('EDIT_LOT', function (idLot) {
      // console.log('edit_lot event lotsdetail')
      if (_this2.idLot === idLot) {
        _this2.modeEdit = true;
        _this2.rowsForCanceling = JSON.parse(JSON.stringify(_this2.lotsDetail));
        _this2.rowsForSaving = [];
        _this2.refresh++;

        _eventBus.EventBus.$emit('MODE_EDIT', true);
      }
    });

    _eventBus.EventBus.$on('REFRESH', function () {
      var typeLigne = [CONST.LOT_DETAIL.TYPE_LIGNE.INITIALE, CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE];
      _this2.lotsDetail = _this2.$store.getters.affaire.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.calculs = _this2.$store.getters.affaire.calculs.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.totaux = _this2.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this2.idLot;
      });
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('EDIT_LOT');

    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    cellClass: function cellClass(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (row.type_lot_detail === CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE && ['mtObjDebourseExec', 'mtMargeBrute', 'pcMargeBrute'].includes(column.property)) {
        return 'tb-pattern';
      }
    },
    isValidData: function isValidData() {
      this.errCheckLibelle = false;
      this.errCheckPrestataire = false;
      var rows = this.$refs['dataTable'].tableData;

      for (var i = 0; i < rows.length; i++) {
        // control: le libellé de la ligne doit être renseigné
        if (rows[i].libelle.trim() === '' && rows[i].type_lot_detail !== 0) {
          this.errCheckLibelle = true;
        } // control: le prestataire doit être renseigné si un montant de derniere proposition presta est saisi pour les lignes cochées


        if ((!rows[i].prestataire || rows[i].prestataire.trim() === '') && rows[i].mt_proposition_prestataire && rows[i].validation > 0) {
          this.errCheckPrestataire = true;
        }
      }

      return !this.errCheckLibelle && !this.errCheckPrestataire;
    },
    // gestion de la sélection multiple colonne validation
    toggleSelection: function toggleSelection(checked) {
      var _this3 = this;

      if (checked) {
        this.$refs.dataTable.toggleAllSelection();
        this.lotsDetail.map(function (row, index) {
          if (Number(_this3.calculs[index].validation) !== 1) {
            row.validation = 2;
            row.date_validation = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');

            if (_this3.rowsForSaving.indexOf(row.id) === -1) {
              _this3.rowsForSaving.push(row.id);
            }
          }
        });
      } else {
        this.lotsDetail.map(function (row, index) {
          if (Number(_this3.calculs[index].validation) !== 1) {
            row.validation = 0;
            row.date_validation = null;

            if (_this3.rowsForSaving.indexOf(row.id) === -1) {
              _this3.rowsForSaving.push(row.id);
            }
          }
        });
        this.$refs.dataTable.clearSelection();
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // --------------------------------------------------
    handleAdd: function handleAdd() {
      this.handleSave();
      var row = {
        id_lot: this.idLot,
        libelle: '',
        mt_vente_initiale: null,
        mt_proposition_prestataire: null,
        type_lot_detail: CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE,
        ordre: Math.max.apply(Math, _toConsumableArray(this.lotsDetail.map(function (o) {
          return o.ordre;
        })).concat([0])) + 1,
        id_debourseIndirect: 0,
        validation: 0
      };
      this.action('insertLotDetail', row);
      this.modeEdit = true;

      _eventBus.EventBus.$emit('MODE_EDIT', true);
    },
    handleDelete: function handleDelete(scope) {
      var _this4 = this;

      var msg = 'Voulez-vous supprimer cette ligne ?';
      this.$confirm(msg, 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this4.action('deleteLotDetail', scope.row);

        var index = _this4.rowsForSaving.findIndex(function (id) {
          return id === scope.row.id;
        });

        if (index > -1) {
          _this4.rowsForSaving.splice(index, 1);
        }
      });
    },
    handleInput: function handleInput(scope, action) {
      // ajout de la date de validation si le champ modifié est la case à cocher
      // si coché => date/heure courante
      // sinon => ''
      if (scope.column['property'] === 'validation') {
        scope.row['date_validation'] = scope.row['validation'] === 1 ? (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss') : null;
      } // mémorisation de la ligne modifiée pour ne sauvegarder + tard que celles concernées
      // et pas toutes les lignes de lotsDetail (on optimise le nombre de requete sql à faire)


      if (this.rowsForSaving.indexOf(scope.row.id) === -1) {
        this.rowsForSaving.push(scope.row.id);
      }

      this.$store.dispatch('affaire/setLotDetail', scope.row).then(function () {
        if (action === 'refresh_calcul') {
          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        }
      });
    },
    handleCancel: function handleCancel() {
      var _this5 = this;

      // this.isValidData()
      // if (!this.errCheckLibelle) {
      this.errCheckLibelle = false;
      this.errCheckPrestataire = false;
      this.modeEdit = false;
      this.refresh++;
      var rows = this.rowsForCanceling;
      rows.forEach(function (row, index) {
        _this5.$store.dispatch('affaire/setLotDetail', row);
      });
      this.rowsForCanceling = [];
      this.rowsForSaving = [];

      _eventBus.EventBus.$emit('REFRESH_CALCULS');

      _eventBus.EventBus.$emit('MODE_EDIT', false); // }

    },
    handleSave: function handleSave() {
      var _this6 = this;

      if (this.isValidData()) {
        this.modeEdit = false;
        this.refresh++;

        var _loop = function _loop(i) {
          var index = _this6.lotsDetail.findIndex(function (row) {
            return row.id === _this6.rowsForSaving[i];
          });

          if (index > -1) {
            // pour éviter un bug si la zone de saisie est vide
            var mt = _this6.lotsDetail[index].mt_proposition_prestataire;
            _this6.lotsDetail[index].mt_proposition_prestataire = mt === '' ? null : mt; // pour eviter un bug si on a ajouter/modifier un avenant au marché principal avant de passer par ici

            delete _this6.lotsDetail[index].mt_avenant; // pour eviter un bug si on a ajouter/modifier un transfert avant de passer par ici

            delete _this6.lotsDetail[index].mt_transfert; // pour eviter un bug lors de la mise à jour du lot detail si on vient précedemment de l'écran de gestion mensuelle

            delete _this6.lotsDetail[index].pc_avancement_cumule;
            delete _this6.lotsDetail[index].mt_facture; // mise à jour

            _this6.action('updateLotDetail', _this6.lotsDetail[index]);
          }
        };

        for (var i = 0; i < this.rowsForSaving.length; i++) {
          _loop(i);
        }

        _eventBus.EventBus.$emit('MODE_EDIT', false);
      }
    },
    // -----------------------------------------------
    action: function action(_action, lotdetail) {
      var _this7 = this;

      // console.log(action, lotdetail)
      this.$store.dispatch('affaire/' + _action, lotdetail).then(function () {
        _this7.$store.dispatch('affaire/setLotDetail', lotdetail).then(function () {
          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        });
      }).catch(function (error) {
        _this7.$message(error);
      });
    }
  }
};
exports.default = _default;