var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "synthese-gestion" },
    [
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [_vm._v("Synthèse de Gestion")])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-view"
                  },
                  on: { click: _vm.validateForm }
                },
                [_vm._v("Rechercher")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                [_vm._v("Reset")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-d-arrow-right",
                    type: "primary",
                    size: "mini",
                    loading: _vm.btnExportLoading.excel,
                    disabled: !(_vm.rptData && _vm.rptData.length > 0)
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleExport("excel")
                    }
                  }
                },
                [_vm._v("\n          Export Excel\n        ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          key: "Form_" + _vm.refresh,
          ref: "ElForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "250px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "10px 0 10px 0" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Secteur d'activité :",
                        prop: "secteursact"
                      }
                    },
                    [
                      _c("listSecteurActMultiple", {
                        attrs: { data: _vm.secteursact },
                        on: {
                          "update:data": function($event) {
                            _vm.secteursact = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Client :", prop: "clients" } },
                    [
                      _c("listClientMultiple", {
                        attrs: { data: _vm.clients },
                        on: {
                          "update:data": [
                            function($event) {
                              _vm.clients = $event
                            },
                            function($event) {
                              _vm.enseignes = []
                              _vm.refreshEnseigne++
                            }
                          ]
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Enseigne :", prop: "enseignes" } },
                    [
                      _c("listEnseigneMultiple", {
                        key: "Enseigne_" + _vm.refreshEnseigne,
                        attrs: { data: _vm.enseignes, idsClients: _vm.clients },
                        on: {
                          "update:data": function($event) {
                            _vm.enseignes = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Secteur géographique :",
                        prop: "secteursgeo"
                      }
                    },
                    [
                      _c("listSecteurGeoMultiple", {
                        attrs: { data: _vm.secteursgeo },
                        on: {
                          "update:data": function($event) {
                            _vm.secteursgeo = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Conducteur :", prop: "conducteur" } },
                    [
                      _c("listCascaderUtilisateur", {
                        key: "conducteur_" + _vm.conducteur,
                        attrs: {
                          idUserSelected: _vm.conducteur,
                          idSociete: _vm.user.id_societe,
                          user: _vm.conducteur,
                          clearable: true
                        },
                        on: {
                          "update:idUserSelected": function($event) {
                            _vm.conducteur = $event
                          },
                          "update:id-user-selected": function($event) {
                            _vm.conducteur = $event
                          },
                          "update:idSociete": function($event) {
                            return _vm.$set(_vm.user, "id_societe", $event)
                          },
                          "update:id-societe": function($event) {
                            return _vm.$set(_vm.user, "id_societe", $event)
                          },
                          "update:user": function($event) {
                            _vm.conducteur = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Classement :", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "Sélection",
                            size: "mini",
                            multiple: "",
                            clearable: "",
                            filtreable: ""
                          },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Gestion mensuelle de :",
                        prop: "currentDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          format: "MM/yyyy",
                          "value-format": "yyyyMM",
                          size: "mini",
                          type: "month",
                          placeholder: "MM/AAAA"
                        },
                        model: {
                          value: _vm.currentDate,
                          callback: function($$v) {
                            _vm.currentDate = $$v
                          },
                          expression: "currentDate"
                        }
                      }),
                      _vm._v(" "),
                      _vm.showError
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "small",
                                type: "danger",
                                effect: "dark"
                              }
                            },
                            [
                              _vm._v(
                                "\n            Aucune donnée pour la période de gestion mensuelle sélectionnée\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            key: "Table_" + _vm.refresh,
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.rptData,
              "summary-method": _vm.getSummaries,
              size: "mini",
              "show-summary": "",
              height: "calc(100vh - 350px)"
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "num_affaire",
                label: "Code affaire",
                width: "250",
                fixed: "left"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "router-link",
                        { attrs: { to: "/affaire/" + scope.row.id } },
                        [
                          _c("el-link", { attrs: { type: "primary" } }, [
                            _vm._v(_vm._s(scope.row.num_affaire))
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "nom",
                label: "Affaire",
                width: "300",
                fixed: "left"
              }
            }),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: { label: "Données générales affaire", align: "center" }
              },
              [
                _c(
                  "el-table-column",
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtCAinitialHT",
                        label: "CA initial HT",
                        width: "120",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(scope.row.mtCAinitialHT)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtMB_Etude",
                        label: "MB étude",
                        width: "120",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(scope.row.mtMB_Etude)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcMB_Etude",
                        label: "%MB",
                        width: "120",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(scope.row.pcMB_Etude)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtCaFdcHT",
                        label: "CA Fdc HT",
                        width: "120",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(scope.row.mtCaFdcHT)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtMB_Exe",
                        label: "MB Exé",
                        width: "120",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(_vm._f("toCurrency")(scope.row.mtMB_Exe))
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcMB_Exe",
                        label: "%MB",
                        width: "120",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(scope.row.pcMB_Exe)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { "class-name": "separator", width: "15" } },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Données affaire", align: "center" } },
              [
                _c(
                  "el-table-column",
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "% Avancement cumulé",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(
                                    scope.row.pcAvancementGlobal
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtEngages",
                        label: "Engagés",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(scope.row.mtEngages)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { "class-name": "separator", width: "15" } },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Gestion N-1", align: "center" } },
              [
                _c(
                  "el-table-column",
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtPM_ConsommeNmoins1",
                        label: "PM",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    scope.row.mtPM_ConsommeNmoins1
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtMB_ConsommeNmoins1",
                        label: "MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    scope.row.mtMB_ConsommeNmoins1
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcMB_ConsommeNmoins1",
                        label: "%MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(
                                    scope.row.pcMB_ConsommeNmoins1
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { "class-name": "separator", width: "15" } },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Gestion M", align: "center" } },
              [
                _c(
                  "el-table-column",
                  { attrs: { label: "Mensuelle", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtPM_M",
                        label: "PM",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(_vm._f("toCurrency")(scope.row.mtPM_M))
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtMB_M",
                        label: "MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(_vm._f("toCurrency")(scope.row.mtMB_M))
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcMB_M",
                        label: "%MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(_vm._f("toPercentage")(scope.row.pcMB_M))
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { "class-name": "separator", width: "15" } },
                  [_vm._v(" ")]
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "Cumulée N", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtPM_CumulN",
                        label: "PM",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(scope.row.mtPM_CumulN)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtMB_CumulN",
                        label: "MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(scope.row.mtMB_CumulN)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcMB_CumulN",
                        label: "%MB",
                        align: "right",
                        with: "110"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(scope.row.pcMB_CumulN)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { "class-name": "separator", width: "15" } },
                  [_vm._v(" ")]
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "Cumulée totale", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtPM_CumuleTotal",
                        label: "PM",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    scope.row.mtPM_CumuleTotal
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtMB_CumuleTotal",
                        label: "MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    scope.row.mtMB_CumuleTotal
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcMB_CumuleTotal",
                        label: "%MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(
                                    scope.row.pcMB_CumuleTotal
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { "class-name": "separator", width: "15" } },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { label: "Solde à produire", align: "center" } },
              [
                _c(
                  "el-table-column",
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtSoldeAProduire_PM",
                        label: "PM",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    scope.row.mtSoldeAProduire_PM
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mtSoldeAProduire_MB",
                        label: "MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    scope.row.mtSoldeAProduire_MB
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pcSoldeAProduire_MB",
                        label: "%MB",
                        align: "right",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toPercentage")(
                                    scope.row.pcSoldeAProduire_MB
                                  )
                                )
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }