"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insert = insert;
exports.update = update;
exports.getAllByAffaireId = getAllByAffaireId;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function insert(data) {
  return (0, _request.default)({
    url: '/avenant_mprinc',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/avenant_mprinc/' + id,
    method: 'put',
    data: data
  });
}

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/avenant_mprinc/affaire/' + id,
    method: 'get'
  });
}