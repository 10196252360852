"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.validURL = validURL;
exports.validEmail = validEmail;
exports.validPhone = validPhone;
exports.isArray = isArray;
exports.isStrongPassword = isStrongPassword;
exports.ConfirmPassword = exports.Password = exports.Email = void 0;

/* eslint-disable */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

function validPhone(phone) {
  var reg = /^\d{2,3} \d{2} \d{2} \d{2} \d{2}$/;
  return reg.test(phone);
}

function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
}

function isStrongPassword(value) {
  /*
  ^	                The password string will start this way
  (?=.*[a-z])	      The string must contain at least 1 lowercase alphabetical character
  (?=.*[A-Z])	      The string must contain at least 1 uppercase alphabetical character
  (?=.*[0-9])	      The string must contain at least 1 numeric character
  (?=.[!@#\$%\^&])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
  (?=.{8,})	        The string must be eight characters or longer
  */
  // const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
  var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'); // const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')

  return strongRegex.test(value);
}

var Email = function Email(rule, value, callback) {
  if (value === '') {
    callback(new Error('Adresse email obligatoire'));
  } else {
    if (!validEmail(value)) {
      callback(new Error('Adresses email non valide'));
    }

    callback();
  }
};

exports.Email = Email;

var Password = function Password(rule, value, callback) {
  if (value === '') {
    callback(new Error('Mot de passe obligatoire'));
  } else {
    if (!isStrongPassword(value)) {
      callback(new Error('Le mot de passe ne respecte pas les règles de sécurité'));
    }

    callback();
  }
};

exports.Password = Password;

var ConfirmPassword = function ConfirmPassword(pwd1, pwd2) {
  if (pwd2 === '') {
    return new Error('Mot de passe de confirmation obligatoire');
  } else {
    if (pwd1 !== pwd2) {
      return new Error('Mot de passe de confirmation non valide');
    }
  }
};

exports.ConfirmPassword = ConfirmPassword;