"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

var _treeAcl = _interopRequireDefault(require("@/components/treeAcl"));

var _listPermissions = _interopRequireDefault(require("@/components/listPermissions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  props: {
    formData: Object,
    idSociete: Number,
    title: String,
    action: String
  },
  components: {
    treeAcl: _treeAcl.default,
    listPermissions: _listPermissions.default
  },
  data: function data() {
    var _this = this;

    var validRole = function validRole(rule, value, callback) {
      if (!_this.formData.name || _this.formData.name && _this.formData.name.trim() === '') {
        callback(new Error('Champ obligatoire'));
      }

      callback();
    };

    return {
      rules: {
        key_role: {
          required: true,
          message: 'Champ obligatoire',
          validator: validRole
        }
      },
      societe: {}
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'user', 'permission'])),
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;

            case 'edit':
              _this2.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      this.$loader(true);
      var data = this.getData();
      apiPermission.insert(data).then(function (results) {
        _this3.$message('Création effectuée avec succès');

        if (_this3.user.id_societe === _this3.formData.id_societe && _this3.user.key_role === _this3.formData.key_role) {
          _this3.$store.commit('permission/SET_ACL', {
            acl: _this3.formData.acl,
            rootacl: _this3.formData.rootacl
          });

          _this3.$store.commit('permission/SET_RULES', _this3.formData.rules);

          _this3.$store.dispatch('permission/generateRoutes');
        }

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      this.$loader(true);
      var data = this.getData();
      apiPermission.update(this.formData.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');

        if (_this4.user.id_societe === _this4.formData.id_societe && _this4.user.key_role === _this4.formData.key_role) {
          _this4.$store.commit('permission/SET_ACL', {
            acl: _this4.formData.acl,
            rootacl: _this4.formData.rootacl
          });

          _this4.$store.commit('permission/SET_RULES', _this4.formData.rules);

          _this4.$store.dispatch('permission/generateRoutes');
        }

        _this4.$loader(false);
      }).catch(function (error) {
        _this4.$message(error);

        _this4.$loader(false);
      });
    },
    makeId: function makeId(length) {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz';
      var charactersLength = characters.length;

      for (var i = 0; i < charactersLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result.substring(0, 10);
    },
    getData: function getData() {
      var data = {
        id_societe: this.idSociete,
        key_role: this.action === 'edit' ? this.formData.key_role : this.makeId(),
        name: this.formData.name,
        acl: this.formData.acl,
        rootacl: this.formData.rootacl,
        rules: this.formData.rules
      };
      return data;
    }
  }
};
exports.default = _default;