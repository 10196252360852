"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByModeleLotId = getAllByModeleLotId;
exports.insert = insert;
exports.update = update;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAllByModeleLotId(idModeleLot) {
  return (0, _request.default)({
    url: '/modele_lot_detail/modele_lot/' + idModeleLot,
    method: 'get'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/modele_lot_detail',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/modele_lot_detail/' + id,
    method: 'put',
    data: data
  });
}