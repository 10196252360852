"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus");

var _ReglementModule = _interopRequireDefault(require("@/views/app/reglements/ReglementModule"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ReglementByFacture',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ReglementModule: _ReglementModule.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    idFacture: {
      type: Number,
      required: true,
      default: 0
    },
    facture: {
      type: Object,
      required: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  // 9. Évènements
  // --
  mounted: function mounted() {
    var _this = this;

    this.$loader(false);

    _eventBus.EventBus.$on('CREANCES_REGLEMENTS_RETURN', function () {
      _this.$router.push({
        name: 'VenteCreance.Creances'
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('CREANCES_REGLEMENTS_RETURN');
  } // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;