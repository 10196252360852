"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _pickBy = _interopRequireDefault(require("lodash/pickBy"));

var _forEach = _interopRequireDefault(require("lodash/forEach"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
import saveState from '@/mixins/saveState.js'

mixins: [saveState],

getSaveStateConfig () {
  return {
    'cacheKey': 'dataTables',
    'saveProperties': ['currentPage', 'pageSize', 'filters']
  }
},
*/
var _default = {
  watch: {
    '$data': {
      handler: function handler() {
        this.saveState();
      },
      deep: true
    }
  },
  created: function created() {
    this.loadState();
  },
  methods: {
    setSavedState: function setSavedState(key, data) {
      localStorage.setItem(key, JSON.stringify(data));
    },
    getSavedState: function getSavedState(key) {
      var savedState = localStorage.getItem(key);
      return savedState ? JSON.parse(savedState) : null;
    },
    removeSavedState: function removeSavedState(key) {
      localStorage.removeItem(key);
    },
    // ---
    loadState: function loadState() {
      var _this = this;

      var savedState = this.getSavedState(this.getSaveStateConfig().cacheKey);

      if (!savedState) {
        return;
      }

      (0, _forEach.default)(savedState, function (value, key) {
        if (_this.attributeIsManagedBySaveState(key)) {
          if (_this.getSaveStateConfig().onLoad) {
            value = _this.getSaveStateConfig().onLoad(key, value);
          }

          _this.$data[key] = value;
        }
      });
    },
    saveState: function saveState() {
      var _this2 = this;

      var data = (0, _pickBy.default)(this.$data, function (value, attribute) {
        return _this2.attributeIsManagedBySaveState(attribute);
      });
      this.setSavedState(this.getSaveStateConfig().cacheKey, data);
    },
    attributeIsManagedBySaveState: function attributeIsManagedBySaveState(attribute) {
      if (this.getSaveStateConfig().ignoreProperties && this.getSaveStateConfig().ignoreProperties.indexOf(attribute) !== -1) {
        return false;
      }

      if (!this.getSaveStateConfig().saveProperties) {
        return true;
      }

      return this.getSaveStateConfig().saveProperties.indexOf(attribute) !== -1;
    },
    clearSavedState: function clearSavedState() {
      this.removeSavedState(this.getSaveStateConfig().cacheKey);
    }
  }
};
exports.default = _default;