"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _vuex = require("vuex");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'LotDetailAvenants',
  props: ['idLot'],
  data: function data() {
    return {
      refresh: 0,
      lotsDetail: [],
      calculs: [],
      totaux: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['affaire'])),
  created: function created() {
    var _this = this;

    this.$watch('$root.modeEdit', function (newValue) {
      if (newValue === false) {
        _this.modeEdit = false;
      }
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      var typeLigne = [CONST.LOT_DETAIL.TYPE_LIGNE.INITIALE, CONST.LOT_DETAIL.TYPE_LIGNE.AVENANT, CONST.LOT_DETAIL.TYPE_LIGNE.AVENANT_MARCHE_PRINCIPAL, CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE];
      _this2.lotsDetail = _this2.$store.getters.affaire.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.calculs = _this2.$store.getters.affaire.calculs.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.totaux = _this2.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this2.idLot;
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    dateColAvenant: function dateColAvenant(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenant[numCol];
      }
    },
    mtTotalLotColonneAvenant: function mtTotalLotColonneAvenant(index, column) {
      return this.totaux[index] && this.totaux[index].mtTotalLotColonneAvenant[column] ? this.totaux[index].mtTotalLotColonneAvenant[column] : '';
    },
    mtTotalLotLigneAvenant: function mtTotalLotLigneAvenant(index) {
      return this.totaux[index] ? this.totaux[index].mtTotalLotLigneAvenant : '';
    },
    // -- Marche principal --
    dateColAvenantMarchePrincipal: function dateColAvenantMarchePrincipal(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenantMarchePrincipal[numCol];
      }
    },
    mtTotalLotColonneAvenantMarchePrincipal: function mtTotalLotColonneAvenantMarchePrincipal(index, column) {
      return this.totaux[index] && this.totaux[index].mtTotalLotColonneAvenantMarchePrincipal[column] ? this.totaux[index].mtTotalLotColonneAvenantMarchePrincipal[column] : '';
    },
    mtTotalLotLigneAvenantMarchePrincipal: function mtTotalLotLigneAvenantMarchePrincipal(index) {
      return this.totaux[index] ? this.totaux[index].mtTotalLotLigneAvenantMarchePrincipal : '';
    },
    renderHeader: function renderHeader(h, _ref) {
      var column = _ref.column,
          $index = _ref.$index;
      var label = column.label.split('\\n');
      return h('div', {}, [h('span', label[0]), h('br'), h('span', label[1])]);
    }
  }
};
exports.default = _default;