"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constantes = require("@/helpers/constantes");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'MontantTypeSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    typeMontantSelected: {
      type: Number,
      validator: function validator(value) {
        for (var _i = 0, _Object$entries = Object.entries(_constantes.TYPES_MONTANT); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              row = _Object$entries$_i[1];

          if (value === row.value) {
            return true;
          }
        }

        return false;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      typeMontantList: _constantes.TYPES_MONTANT,
      typeMontant: this.typeMontantSelected
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    onClear: function onClear() {
      this.$emit('update:type-montant-selected', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:type-montant-selected', index);
      }
    }
  }
};
exports.default = _default;