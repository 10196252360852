"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  data: function data() {
    return {
      levelList: null,
      EventBus: _eventBus.EventBus
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['breadcrumb'])),
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      /*
      const first = matched[0]
       // HACK for nested routes to display parent menu in breadcrumb
       const path = matched[matched.length - 1].path
       let prev = path.split('/:')[0]
      prev = prev.split('/')
      prev.pop()
      prev = prev.join('/')
      if (prev && prev !== first.path) {
        const arrRoutes = this.generateArr(this.$router.options.routes)
        const parent = arrRoutes.find(x => x.path === prev)
        matched.splice(matched.length - 1, 0, parent)
      }
      */
      // ======

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;

      var toPath = _pathToRegexp.default.compile(path);

      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.replace(redirect, function () {});
        return;
      }

      this.$router.push(this.pathCompile(path));
    },
    generateArr: function generateArr(routes) {
      var _this = this;

      var data = [];
      routes.forEach(function (route) {
        data.push(route);

        if (route.children) {
          var temp = _this.generateArr(route.children);

          if (temp.length > 0) {
            data = [].concat(_toConsumableArray(data), _toConsumableArray(temp));
          }
        }
      });
      return data;
    }
  }
};
exports.default = _default;