"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiSociete = _interopRequireWildcard(require("@/api/societe"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Societe',
  props: ['idSocieteSelected', 'societe', 'placeholder'],
  data: function data() {
    return {
      societes: [],
      idSociete: this.idSocieteSelected,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiSociete.getAllActif().then(function (results) {
      _this.societes = results;

      var societe = _this.societes.find(function (x) {
        return Number(x.id) === Number(_this.idSocieteSelected);
      });

      if (societe) {
        _this.$emit('update:societe', societe);
      }
    }).then(function () {
      _this.show = true;
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:id-societe-selected', null);
      this.$emit('update:societe', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-societe-selected', index);
        this.$emit('update:societe', this.societes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  }
};
exports.default = _default;