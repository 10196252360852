"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'AffaireTotauxAvenants',
  data: function data() {
    return {
      refresh: 0,
      calculs: [],
      lots: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.calculs = _this.$store.getters.affaire.calculs;
      _this.lots = _this.$store.getters.affaire.lots;
      _this.refresh++;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['affaire'])),
  methods: {
    dateColAvenant: function dateColAvenant(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenant[numCol];
      }
    },
    dateColAvenantMarchePrincipal: function dateColAvenantMarchePrincipal(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenantMarchePrincipal[numCol];
      }
    }
  }
};
exports.default = _default;