"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _vuex = require("vuex");

var _listSociete = _interopRequireDefault(require("@/components/listSociete"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'Login',
  components: {
    listSociete: _listSociete.default
  },
  data: function data() {
    var validateSociete = function validateSociete(rule, value, callback) {
      if (value > 0) {
        callback();
      } else {
        callback(new Error('Société obligatoire'));
      }
    };

    var validateEmail = function validateEmail(rule, value, callback) {
      if (value === '') {
        callback(new Error('Adresse email obligatoire'));
      } else if (!validate.validEmail(value)) {
        callback(new Error('Adresse email non valide'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('Mot de passe obligatoire'));
      } else {
        callback();
      }
    };

    return {
      selection: {},
      loginForm: {
        email: '',
        password: '',
        id_societe: ''
      },
      loginRules: {
        id_societe: [{
          required: true,
          validator: validateSociete
        }],
        email: [{
          required: true,
          validator: validateEmail
        }],
        password: [{
          required: true,
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['gitInfo'])),
  beforeMount: function beforeMount() {
    this.loginForm.id_societe = Number(_jsCookie.default.get('loginSociete')) || '';
    this.$store.dispatch('app/getGitInfo');
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;

      this.passwordType = this.passwordType === 'password' ? '' : 'password';
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.$loader(true);

          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            _jsCookie.default.set('loginSociete', _this2.loginForm.id_societe);

            _this2.loading = false;

            _this2.$router.push({
              path: _this2.redirect || '/'
            });

            _this2.$loader(false);
          }).catch(function () {
            _this2.loading = false;

            _this2.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;