"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByAffaireId = getAllByAffaireId;
exports.getAllByFactureId = getAllByFactureId;
exports.getById = getById;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.bulkDelete = bulkDelete;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/reglement/affaire/' + id,
    method: 'get'
  });
}

function getAllByFactureId(id) {
  return (0, _request.default)({
    url: '/reglement/facture/' + id,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/reglement/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/reglement/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/reglement',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/reglement/' + id,
    method: 'delete'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/reglement/' + ids,
    method: 'delete'
  });
}