"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiWorkflow = _interopRequireWildcard(require("@/api/workflow"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      CONST: CONST,
      etapes: [],
      row: [],
      data: [],
      multipleSelection: [],
      columns: [{
        prop: 'etape',
        label: 'Étape'
      }, {
        prop: 'niveau',
        label: 'Niveau'
      }, {
        prop: 'id_secteur_act',
        label: 'Secteur activité'
      }, {
        prop: 'name',
        label: 'Profil'
      }]
    };
  },
  mounted: function mounted() {
    for (var index in CONST.AFFAIRE.ETAPE) {
      this.etapes[CONST.AFFAIRE.ETAPE[index].value] = CONST.AFFAIRE.ETAPE[index].title;
    } // --


    this.loadData();
  },
  methods: {
    libelleEtape: function libelleEtape(etape) {
      return this.etapes[etape];
    },
    libNiveau: function libNiveau(niveau) {
      switch (niveau) {
        case 0:
          return 'Saisie';

        case 1:
          return 'Validation niveau 1';

        case 2:
          return 'Validation niveau 2';

        default:
          return '';
      }
    },
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      apiWorkflow.getAllBySocieteId(this.$store.getters.user.id_societe).then(function (results) {
        if (results && results.length > 0) {
          _this.data = results;
          _this.total = results.length;
        }

        _this.$loader(false);
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    ColWidth: function ColWidth(index) {
      // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
      switch (index) {
        case 0:
          // etape
          return 200;

        case 1:
          // Niveau
          return 180;

        case 2:
          // Secteur activité
          return 250;

        case 3:
          // Profil
          return 300;

        case 4:
          // lien affaire
          return 200;

        default:
          return 80;
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.row = val;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm('Voulez-vous supprimer la ligne sélectionnée ' + row.code + ' ?', 'Confirmer la suppression ?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiWorkflow.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');

          _this2.$loader(false);
        }).catch(function (err) {
          _this2.$message(err);

          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiWorkflow.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();

          _this3.$loader(false);
        }).catch(function (error) {
          _this3.$message(error);

          _this3.$loader(false);
        });
      });
    }
  }
};
exports.default = _default;