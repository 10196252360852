"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'listEtape',
  props: ['valueEtapeSelected', 'etape', 'placeholder'],
  data: function data() {
    return {
      etapes: [],
      valueEtape: this.valueEtapeSelected,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    this.etapes = CONST.AFFAIRE.ETAPE;
    this.onChange(this.valueEtapeSelected);
    this.show = true;
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:value-etape-selected', null);
      this.$emit('update:etape', null);
    },
    onChange: function onChange(value) {
      for (var etape in this.etapes) {
        if (Number(this.etapes[etape].value) === Number(value)) {
          this.$emit('update:value-etape-selected', value);
          this.$emit('update:etape', this.etapes[etape]);
        }
      }
    }
  }
};
exports.default = _default;