"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var apiAffaire = _interopRequireWildcard(require("@/api/affaire"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _moment = _interopRequireDefault(require("moment"));

var _StickyTable = _interopRequireDefault(require("@/components/StickyTable.vue"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination.vue"));

var _ScrollBar = _interopRequireDefault(require("@/components/ScrollBar.vue"));

var _listSecteurActMultiple = _interopRequireDefault(require("@/components/listSecteurActMultiple"));

var _listClientMultiple = _interopRequireDefault(require("@/components/listClientMultiple"));

var _listEnseigneMultiple = _interopRequireDefault(require("@/components/listEnseigneMultiple"));

var _listSecteurGeoMultiple = _interopRequireDefault(require("@/components/listSecteurGeoMultiple"));

var _listCascaderUtilisateur = _interopRequireDefault(require("@/components/listCascaderUtilisateur"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var _default = {
  name: 'AffaireList',
  components: {
    StickyTable: _StickyTable.default,
    Pagination: _Pagination.default,
    ScrollBar: _ScrollBar.default,
    listSecteurActMultiple: _listSecteurActMultiple.default,
    listClientMultiple: _listClientMultiple.default,
    listEnseigneMultiple: _listEnseigneMultiple.default,
    listSecteurGeoMultiple: _listSecteurGeoMultiple.default,
    listCascaderUtilisateur: _listCascaderUtilisateur.default
  },
  data: function data() {
    return {
      currentPage: 1,
      pageSize: 100,
      show: false,
      refresh: 0,
      data: [],
      dataTable: [],
      CONST: CONST,
      // --
      clients: [],
      enseignes: [],
      secteursAct: [],
      secteursGeo: [],
      conducteur: [],
      etape: [],
      statusAff: [],
      numNomAffaire: '',
      // --
      refreshEnseigne: 0,
      filters: [],
      rules: {},
      listEtapes: [{
        label: 'Vente initiale',
        value: CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value
      }, {
        label: 'Objectif déboursé',
        value: CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value
      }, {
        label: 'Budget affaire',
        value: CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value
      }, {
        label: 'Gestion mensuelle',
        value: CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
      }],
      listStatus: [{
        label: 'Tout',
        value: [CONST.AFFAIRE.STATUS.DRAFT.value, CONST.AFFAIRE.STATUS.CREATED.value, CONST.AFFAIRE.STATUS.INPROGRESS.value, CONST.AFFAIRE.STATUS.DENIED.value, CONST.AFFAIRE.STATUS.DENIED1.value, CONST.AFFAIRE.STATUS.DENIED2.value, CONST.AFFAIRE.STATUS.TOVALIDATE.value, CONST.AFFAIRE.STATUS.TOVALIDATE1.value, CONST.AFFAIRE.STATUS.TOVALIDATE2.value, CONST.AFFAIRE.STATUS.VALIDATED.value, CONST.AFFAIRE.STATUS.VALIDATED1.value, CONST.AFFAIRE.STATUS.VALIDATED2.value, CONST.AFFAIRE.STATUS.PENDING.value, CONST.AFFAIRE.STATUS.VALIDSYNTHESE.value, CONST.AFFAIRE.STATUS.ASKSLEEPING.value, CONST.AFFAIRE.STATUS.ASKARCHIVING.value, CONST.AFFAIRE.STATUS.ASKWAKEUP.value, CONST.AFFAIRE.STATUS.CLOSED.value, CONST.AFFAIRE.STATUS.SLEEPING.value, CONST.AFFAIRE.STATUS.ARCHIVED.value].join(',')
      }, {
        label: 'En cours',
        value: [CONST.AFFAIRE.STATUS.CREATED.value, CONST.AFFAIRE.STATUS.INPROGRESS.value, CONST.AFFAIRE.STATUS.DENIED.value, CONST.AFFAIRE.STATUS.DENIED1.value, CONST.AFFAIRE.STATUS.DENIED2.value, CONST.AFFAIRE.STATUS.TOVALIDATE.value, CONST.AFFAIRE.STATUS.TOVALIDATE1.value, CONST.AFFAIRE.STATUS.TOVALIDATE2.value, CONST.AFFAIRE.STATUS.VALIDATED.value, CONST.AFFAIRE.STATUS.VALIDATED1.value, CONST.AFFAIRE.STATUS.VALIDATED2.value, CONST.AFFAIRE.STATUS.PENDING.value, CONST.AFFAIRE.STATUS.VALIDSYNTHESE.value, CONST.AFFAIRE.STATUS.ASKSLEEPING.value, CONST.AFFAIRE.STATUS.ASKARCHIVING.value, CONST.AFFAIRE.STATUS.ASKWAKEUP.value].join(',')
      }, {
        label: 'Brouillon',
        value: CONST.AFFAIRE.STATUS.DRAFT.value
      }, {
        label: 'En sommeil',
        value: CONST.AFFAIRE.STATUS.SLEEPING.value
      }, {
        label: 'Clôturé',
        value: CONST.AFFAIRE.STATUS.CLOSED.value
      }, {
        label: 'Archivé',
        value: CONST.AFFAIRE.STATUS.ARCHIVED.value
      }],
      filterSecteurAct: [],
      filterSecteurGeo: [],
      filterClient: [],
      filterEnseigne: [],
      filterConducteur: [],
      filterEtape: [],
      filterStatus: [],
      filterAffaire: [],
      // ---
      libStatus: [],
      libEtape: [],
      // ---
      cookie: [],
      activeFilter: ''
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.show = false;
    this.$loader(true);
    apiAffaire.getAllBySocieteId(this.user.id_societe).then(function (results) {
      _this.data = results;
      _this.dataTable = results;
    }).then(function () {
      // eslint-disable-next-line
      for (var _i = 0, _Object$entries = Object.entries(_this.CONST.AFFAIRE.STATUS); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            element = _Object$entries$_i[1];

        _this.libStatus[element.value] = element.title;
      } // eslint-disable-next-line


      for (var _i2 = 0, _Object$entries2 = Object.entries(_this.CONST.AFFAIRE.ETAPE); _i2 < _Object$entries2.length; _i2++) {
        var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
            _key = _Object$entries2$_i[0],
            _element = _Object$entries2$_i[1];

        _this.libEtape[_element.value] = _element.title;
      }
    }).then(function () {
      // chargement et application des filtres sauvegardés en cookie s'il y en a
      _this.getCookie(); // this.refresh++

    }).finally(function () {
      _this.refresh++;
      _this.show = true;

      _this.$loader(false);
    }).catch(function (error) {
      _this.$message(error);

      _this.show = true;

      _this.$loader(false);
    });
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['notifications', 'user', 'workflow']), {
    total: function total() {
      return this.dataTable.length;
    },
    nbBrouillon: function nbBrouillon() {
      var drafts = this.data.filter(function (x) {
        return x.status === CONST.AFFAIRE.STATUS.DRAFT.value;
      });
      return drafts ? drafts.length : 0;
    },
    CookieAffaire: function CookieAffaire() {
      return 'cookieAffaire_' + this.user.id_societe;
    }
  }),
  methods: {
    handleSortChange: function handleSortChange(_ref) {
      var prop = _ref.prop,
          order = _ref.order;
      this.data.sort(function (a, b) {
        var aValue = a[prop];
        var bValue = b[prop];
        return order === 'ascending' ? aValue - bValue : bValue - aValue;
      });
    },
    formatePeriode: function formatePeriode(periode) {
      return periode ? (0, _moment.default)(periode, 'YYYYMM').add('-1', 'months').format('MM/YYYY') : '';
    },
    getLibStatus: function getLibStatus(status) {
      return this.libStatus[status];
    },
    getLibEtape: function getLibEtape(etape) {
      return this.libEtape[etape];
    },
    // ---------------
    getCookie: function getCookie() {
      var cookie = _jsCookie.default.get(this.CookieAffaire) ? JSON.parse(_jsCookie.default.get(this.CookieAffaire)) : [];
      this.secteursAct = cookie.secteursAct || [];
      this.secteursGeo = cookie.secteursGeo || [];
      this.clients = cookie.clients || [];
      this.enseignes = cookie.enseignes || [];
      this.conducteur = cookie.conducteur || null;
      this.etape = cookie.etape || null;
      this.statusAff = cookie.status || null;
      this.numNomAffaire = cookie.numNomAffaire || '';
      this.activeFilter = this.isExistFilters() ? 'filters' : '';
      this.setFilterSecteurAct(this.secteursAct);
      this.setFilterClient(this.clients);
      this.setFilterEnseigne(this.enseignes);
      this.setFilterSecteurGeo(this.secteursGeo);
      this.setFilterConducteur(this.conducteur);
      this.setFilterEtape(this.etape);
      this.setFilterStatus(this.statusAff);
      this.setFilterAffaire(this.numNomAffaire);
    },
    setCookie: function setCookie() {
      var cookie = {
        secteursAct: this.secteursAct,
        secteursGeo: this.secteursGeo,
        clients: this.clients,
        enseignes: this.enseignes,
        conducteur: this.conducteur,
        etape: this.etape,
        status: this.statusAff,
        numNomAffaire: this.numNomAffaire
      };

      _jsCookie.default.set(this.CookieAffaire, JSON.stringify(cookie));
    },
    // -----
    isExistFilters: function isExistFilters() {
      return this.clients && this.clients.length > 0 || this.enseignes && this.enseignes.length > 0 || this.secteursAct && this.secteursAct.length > 0 || this.secteursGeo && this.secteursGeo.length > 0 || this.conducteur > 0 || this.etape && this.etape.length > 0 || this.statusAff && this.statusAff.length > 0 || this.numNomAffaire !== '';
    },
    getFilter: function getFilter(selection, criteria) {
      if (selection && selection.length > 0) {
        var _selection = selection;

        if (criteria === 'status') {
          // cas du filtre Classement
          var uniqueNumbers = new Set();
          _selection = selection.flatMap(function (element) {
            if (typeof element === 'number') {
              if (!uniqueNumbers.has(element)) {
                // on vérifie que l'element n'est pas déjà dans le tableau
                uniqueNumbers.add(element);
                return [element];
              }
            } else if (typeof element === 'string') {
              return element.split(',').map(function (numStr) {
                var num = parseInt(numStr, 10);

                if (!uniqueNumbers.has(num)) {
                  // on vérifie que l'element n'est pas déjà dans le tableau
                  uniqueNumbers.add(num);
                  return num;
                }
              }).filter(Boolean); // Filter out undefined values
            }
          });
        } // on filtre en fonction des sélections


        return this.data.filter(function (x) {
          return _selection.includes(x[criteria]);
        });
      } else {
        return this.data;
      }
    },
    applyFilters: function applyFilters(filter) {
      // on recherche l'intersection de tous les tableaux pour avoir les affaires répondant à chaque filtre sélectionné
      this.dataTable = [this.filterSecteurAct, this.filterClient, this.filterEnseigne, this.filterSecteurGeo, this.filterConducteur, this.filterEtape, this.filterStatus, this.filterAffaire].reduce(function (a, c) {
        return a.filter(function (i) {
          return c.includes(i);
        });
      }); // on sauvegarde les filtres sélectionnées dans un cookie pour être rappelé si on revient sur la page

      this.setCookie();
    },
    setFilterSecteurAct: function setFilterSecteurAct(selection) {
      this.secteursAct = selection;
      this.filterSecteurAct = this.getFilter(selection, 'id_secteur_act');
      this.applyFilters('id_secteur_act');
    },
    setFilterSecteurGeo: function setFilterSecteurGeo(selection) {
      this.secteursGeo = selection;
      this.filterSecteurGeo = this.getFilter(selection, 'id_secteur_geo');
      this.applyFilters('id_secteur_geo');
    },
    setFilterClient: function setFilterClient(selection) {
      this.clients = selection;
      this.filterClient = this.getFilter(selection, 'id_client');
      this.applyFilters('id_client');
    },
    setFilterEnseigne: function setFilterEnseigne(selection) {
      this.enseignes = selection;
      this.filterEnseigne = this.getFilter(selection, 'id_enseigne');
      this.applyFilters('id_enseigne');
    },
    setFilterConducteur: function setFilterConducteur(selection) {
      this.conducteur = selection && selection.id ? selection.id : selection;
      var sel = [selection];

      if (selection && selection.id) {
        sel = [selection.id];
      } else if (selection === null) {
        sel = [];
      }

      this.filterConducteur = this.getFilter(sel, 'id_conducteur_travaux');
      this.applyFilters('id_conducteur_travaux');
    },
    setFilterEtape: function setFilterEtape(selection) {
      this.etape = selection;
      this.filterEtape = this.getFilter(selection, 'etape');
      this.applyFilters('etape');
    },
    setFilterStatus: function setFilterStatus(selection) {
      this.statusAff = selection;
      this.filterStatus = this.getFilter(selection, 'status');
      this.applyFilters('status');
    },
    setFilterAffaire: function setFilterAffaire(input) {
      this.numNomAffaire = input;
      var search = input.toUpperCase();
      this.filterAffaire = this.data.filter(function (x) {
        return x.num_affaire.toUpperCase().indexOf(search) >= 0 || x.nom.toUpperCase().indexOf(search) >= 0;
      });
      this.applyFilters('affaire');
    },
    // ===================

    /*
    handleFilterChange (filter) {
      // HACK POUR LE BUG DE LA PAGINATION QUAND ON FILTRE
      if (filter[Object.keys(filter)].length > 0) {
        this.$refs['dataTable'].handleSizeChange(1000)
      } else {
        this.$refs['dataTable'].handleSizeChange(20)
      }
    },
    */
    isExistsNotification: function isExistsNotification(idAffaire) {
      var _this2 = this;

      // recherche s'il ya une notification sur cette affaire pour l'utilisateur connecté afin d'afficher l'indicateur de présence de non consultation de l'affaire
      var notifs = this.notifications.notifications;
      return notifs.find(function (e) {
        return e.id_user === _this2.user.id && e.url_link === '/#/affaire/' + idAffaire && e.date_markasread === null;
      });
    }
  }
};
exports.default = _default;