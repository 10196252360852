"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllBySocieteId = getAllBySocieteId;
exports.getById = getById;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.bulkDelete = bulkDelete;
exports.getUsersToNotify = getUsersToNotify;
exports.sendNotifications = sendNotifications;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAllBySocieteId(idSociete) {
  return (0, _request.default)({
    url: '/workflow/societe/' + idSociete,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/workflow/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/workflow/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/workflow',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/workflow/' + id,
    method: 'delete'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/workflow/' + ids,
    method: 'delete'
  });
}

function getUsersToNotify(data) {
  return (0, _request.default)({
    url: '/workflow/users',
    method: 'post',
    data: data
  });
}

function sendNotifications(data) {
  return (0, _request.default)({
    url: '/workflow/sendnotifications',
    method: 'post',
    data: data
  });
}