"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TestExample',
  data: function data() {
    return {
      myData: [{
        'id': 1,
        'number': '10.04.160',
        'name': 'Alice'
      }, {
        'id': 2,
        'number': '24.12.12',
        'name': 'Bob'
      }, {
        'id': 3,
        'number': '24.12.110',
        'name': 'Charlie'
      }, {
        'id': 4,
        'number': '24.12.11',
        'name': 'Diana'
      }, {
        'id': 5,
        'number': '5.1.160',
        'name': 'Eve'
      }, {
        'id': 6,
        'number': '5.1.2',
        'name': 'Frank'
      }, {
        'id': 7,
        'number': '5.1.17',
        'name': 'Grace'
      }, {
        'id': 8,
        'number': '20.3.4',
        'name': 'Hank'
      }, {
        'id': 9,
        'number': '4.2.7890',
        'name': 'Ivy'
      }, {
        'id': 10,
        'number': '10.04.3',
        'name': 'Jack'
      }],
      columns: {
        'id': {
          key: 0,
          prop: 'id',
          label: 'Id',
          width: 150
        },
        'name': {
          key: 1,
          prop: 'name',
          label: 'Name',
          width: 100
        },
        'number': {
          key: 2,
          prop: 'number',
          label: 'Numéro',
          width: 100
        }
      },
      sortMethod: {
        number: function number(a, b) {
          var numA = a.split('.').map(Number);
          var numB = b.split('.').map(Number);

          for (var i = 0; i < Math.max(numA.length, numB.length); i++) {
            var partA = numA[i] || 0; // Si une partie est absente, considérer comme 0

            var partB = numB[i] || 0;

            if (partA !== partB) {
              return partA - partB; // Trier par différence numérique
            }
          }

          return 0; // Si tous les segments sont égaux
        }
      }
    };
  },
  computed: {
    total: function total() {
      return this.myData.length;
    }
  }
};
exports.default = _default;