"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _listSociete = _interopRequireDefault(require("@/components/listSociete"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PasswordReminder',
  components: {
    listSociete: _listSociete.default
  },
  data: function data() {
    var validateSociete = function validateSociete(rule, value, callback) {
      if (value > 0) {
        callback();
      } else {
        callback(new Error('Société obligatoire'));
      }
    };

    var validateEmail = function validateEmail(rule, value, callback) {
      if (value === '') {
        callback(new Error('Adresse email obligatoire'));
      } else if (!validate.validEmail(value)) {
        callback(new Error('Adresse email non valide'));
      } else {
        callback();
      }
    };

    return {
      selection: {},
      reminderForm: {
        id_societe: '',
        email: ''
      },
      reminderRules: {
        id_societe: [{
          required: true,
          validator: validateSociete
        }],
        email: [{
          required: true,
          validator: validateEmail
        }]
      },
      loading: false
    };
  },
  beforeMount: function beforeMount() {
    this.reminderForm.id_societe = Number(_jsCookie.default.get('loginSociete')) || '';
  },
  methods: {
    resetForm: function resetForm() {
      this.$refs.reminderForm.resetFields();
    },
    handlePasswordReminder: function handlePasswordReminder() {
      var _this = this;

      this.$refs.reminderForm.validate(function (valid) {
        if (valid) {
          _this.$loader(true);

          _this.loading = true;

          _this.$store.dispatch('user/passwordReminder', _this.reminderForm).then(function (results) {
            _this.resetForm();

            _this.$message(_this.$i18n.t(results.message));

            _this.loading = false;

            _this.$loader(false);
          }).catch(function () {
            _this.loading = false;

            _this.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;