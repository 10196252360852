"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _validate = require("@/helpers/validate");

var _utils = require("@/helpers/utils");

var apiUser = _interopRequireWildcard(require("@/api/user"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'UserProfil',
  data: function data() {
    var _this = this;

    var validatePwd1 = function validatePwd1(rule, value, callback) {
      if (value === '') {
        callback(new Error('Veuillez entrer le mot de passe'));
      } else {
        if (!(0, _validate.isStrongPassword)(value)) {
          callback(new Error('Le mot de passe ne respecte pas les règles de sécurité'));
        }
      }

      callback();
    };

    var validatePwd2 = function validatePwd2(rule, value, callback) {
      if (value === '') {
        callback(new Error('Veuillez entrer le mot de passe de confirmation'));
      } else {
        if (value !== _this.form2Data.pwd1) {
          callback(new Error('Les mots de passe ne correspondent pas'));
        }
      }

      callback();
    };

    return {
      form2Data: {
        pwd1: '',
        pwd2: ''
      },
      descForm1: {
        firstname: {
          label: 'Prénom',
          type: 'string',
          required: true,
          min: 3,
          max: 50,
          message: 'Longueur entre 3 et 50 caractères'
        },
        lastname: {
          label: 'Nom',
          type: 'string',
          required: true,
          min: 3,
          max: 50,
          message: 'Longueur entre 3 et 50 caractères'
        }
      },
      dialogForm1Visible: false,
      dialogForm2Visible: false,
      rules: {
        pwd1: [{
          required: true,
          validator: validatePwd1,
          trigger: 'change'
        }],
        pwd2: [{
          required: true,
          validator: validatePwd2,
          trigger: 'change'
        }]
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['user']), {
    notification: {
      get: function get() {
        return Number(this.user.notification_mail) || 0;
      },
      set: function set(value) {
        this.user.notification_mail = value;
      }
    }
  }),
  methods: {
    resetFieldsForm1: function resetFieldsForm1() {
      this.$refs['dialogForm1'].resetFields();
      this.dialogForm1Visible = false;
    },
    resetFieldsForm2: function resetFieldsForm2() {
      this.$refs['dialogForm2'].resetFields();
      this.dialogForm2Visible = false;
    },
    validateForm1: function validateForm1() {
      var _this2 = this;

      this.$refs['dialogForm1'].validate(function (valid) {
        if (valid) {
          var data = {
            firstname: _this2.user.firstname,
            lastname: _this2.user.lastname
          };
          apiUser.update(_this2.user.id, data).then(function (results) {
            _this2.$message('Mise à jour effectuée avec succès');
          }).catch(function (error) {
            _this2.$refs['dialogForm1'].resetFields();

            _this2.$message(error);
          });
          _this2.dialogForm1Visible = false;
        }
      });
    },
    validateForm2: function validateForm2() {
      var _this3 = this;

      this.$refs['dialogForm2'].validate(function (valid) {
        if (valid) {
          var data = {
            password: (0, _utils.encodePassword)(_this3.form2Data.pwd1)
          };
          apiUser.update(_this3.user.id, data).then(function (results) {
            _this3.$message('Mise à jour du mot de passe effectuée avec succès');
          }).catch(function (error) {
            _this3.$refs['dialogForm1'].resetFields();

            _this3.$message(error);
          });
          _this3.dialogForm2Visible = false;
        }
      });
    },
    handleChange: function handleChange() {
      var _this4 = this;

      var data = {
        notification_mail: this.notification
      };
      apiUser.update(this.user.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');
      }).catch(function (error) {
        _this4.$message(error);
      });
    }
  }
};
exports.default = _default;