"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _utils = require("@/helpers/utils");

var _factures = require("@/helpers/factures");

var _vuex = require("vuex");

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  name: 'FactureList',
  inject: ['getCommande' // Permet de récupérer la commande parente éventuellement sélectionnée
  ],
  data: function data() {
    return {
      CONST: CONST,
      dataLoaded: true,
      dialogFactureCommentaireVisible: false,

      /* ---------------------------------------
       *  Forcer l'idCommande parent
       * ---------------------------------------
       *   - pré-filtre de la liste des factures,
       *   - création d'une nouvelle facture
       */
      idCommande: null,
      commande: null,
      row: [],
      dialogDetailVisible: false,
      multipleSelection: [],
      columns: {
        'commande': {
          key: 0,
          prop: 'commande',
          label: 'Commande',
          width: 150
        },
        'tva': {
          key: 1,
          prop: 'tva',
          label: 'Tva',
          width: 100
        },
        'numero': {
          key: 2,
          prop: 'numero',
          label: 'Numéro',
          width: 100
        },
        'date_facture': {
          key: 3,
          prop: 'date_facture',
          label: 'Date',
          width: 110
        },
        'date_echeance': {
          key: 4,
          prop: 'date_echeance',
          label: 'Échéance',
          width: 110
        },
        'montant': {
          key: 5,
          prop: 'montant',
          label: 'Montant',
          width: 120
        },
        'rev_actu': {
          key: 6,
          prop: 'rev_actu',
          label: 'Rev actu',
          width: 120
        },
        'retenue_garantie': {
          key: 7,
          prop: 'retenue_garantie',
          label: 'Retenue de Garantie',
          width: 120
        },
        'divers_ttc': {
          key: 8,
          prop: 'divers_ttc',
          label: 'Divers TTC',
          width: 120
        },
        'libelle': {
          key: 9,
          prop: 'libelle',
          label: 'Libellé facture',
          width: 300
        },
        'emetteur': {
          key: 10,
          prop: 'emetteur',
          label: 'Emetteur',
          width: 350
        },
        'mt_avant_paiement_direct_ttc': {
          key: 11,
          prop: 'mt_avant_paiement_direct_ttc',
          label: 'TTC avant P. direct',
          width: 120
        },
        'mt_paiement_direct_st': {
          key: 12,
          prop: 'mt_paiement_direct_st',
          label: 'Paiement direct st',
          width: 120
        },
        'mt_a_percevoir_ttc': {
          key: 13,
          prop: 'mt_a_percevoir_ttc',
          label: 'Montant à percevoir TTC',
          width: 120
        },
        'fichier': {
          key: 14,
          prop: 'fichier',
          label: 'Fichier',
          width: 100
        },
        'nb_reglement': {
          key: 15,
          prop: 'nb_reglement',
          label: 'Nb règlement',
          width: 150
        },
        'montant_regle': {
          key: 16,
          prop: 'montant_regle',
          label: 'Montant réglé',
          width: 120
        },
        'montant_regle_moins_impute': {
          key: 17,
          prop: 'montant_regle_moins_impute',
          label: 'Montant réglé',
          width: 120
        },
        'montant_ht': {
          key: 18,
          prop: 'montant_ht',
          label: 'Montant HT',
          width: 120
        }
      },
      sortMethod: {
        commande: function commande(a, b) {
          if (a === null && b === null) {
            return 0;
          }

          if (a === null) {
            return -1;
          }

          if (b === null) {
            return 1;
          }

          return a.numero < b.numero;
        },
        tva: function tva(a, b) {
          return a.taux - b.taux;
        }
      },
      filters: [{
        value: '',
        prop: ['command', 'tva', 'numero', 'libelle', 'date_facture', 'date_echeance', 'montant', 'montant_ht', 'mt_a_percevoir_ttc', 'nb_reglement', 'montant_regle'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'commande':
                return row[prop] !== null && String(row[prop].numero).toLowerCase().indexOf(filter.value) > -1;

              case 'tva':
                return String((0, _utils.toPercentage)(row[prop].taux)).indexOf(filter.value) > -1;

              case 'numero':
              case 'libelle':
                return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;

              case 'date_facture':
              case 'date_echeance':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1;

              case 'montant':
              case 'montant_ht':
              case 'montant_regle':
              case 'mt_a_percevoir_ttc':
                return String((0, _utils.toCurrencyFilter)(row[prop])).indexOf(filter.value.toLowerCase()) > -1;

              case 'nb_reglement':
                return String(row[prop]).indexOf(filter.value.toLowerCase()) > -1;

              default:
                return false;
            }
          });
        }
      }, // Filtre (>= date de début de période)
      {
        value: '',
        prop: ['date_facture'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'date_facture':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                if (filter.value === null) {
                  return true;
                } // console.log(prop + ' : ' + moment(String(row[prop])).format('YYYY-MM-DD'))


                return (0, _moment.default)(String(row[prop]), 'YYYY-MM-DD').isSameOrAfter(filter.value);

              default:
                return false;
            }
          });
        }
      }, // Filtre (<= date de fin de période)
      {
        value: '',
        prop: ['date_facture'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            // console.log(`filtre n°3 (index #2) : "${filter.value}"`)
            switch (prop) {
              case 'date_facture':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                if (filter.value === null) {
                  return true;
                } // console.log(prop + ' : ' + moment(String(row[prop])).format('YYYY-MM-DD'))


                return (0, _moment.default)(String(row[prop]), 'YYYY-MM-DD').isSameOrBefore(filter.value);

              default:
                return false;
            }
          });
        }
      }],
      filterDateFacture: [],
      filterDateEcheance: [],
      filterTauxTva: [],
      // pour filtrer les factures par date
      filterDateMin: null,
      filterDateMax: null
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['affaire', 'user']), {}, (0, _vuex.mapGetters)('facturation', ['getFactureList', 'getTauxTvaList', 'getNbReglementByFactureOrImputationSelection']), {
    dateFormat: function dateFormat() {
      return this.user.date_format; // dd/MM/yyyy
    },
    data: function data() {
      this.$loader(true);
      var data = this.getFactureList(this.idCommande); // ---
      // Initialisation des valeurs des filtres de colonnes

      var filterDateFacture = [];
      var filterDateEcheance = [];
      var filterTauxTva = [];
      data.forEach(function (row) {
        // Initialisation des valeurs du filtre pour la colonne "date de facturation"
        if (!filterDateFacture.find(function (x) {
          return x.value === row.date_facture;
        })) {
          if (row.date_facture && typeof row.date_facture !== 'undefined') {
            var item = {
              text: row.date_facture,
              value: row.date_facture
            };
            item.text = (0, _moment.default)(String(row.date_facture)).format('DD/MM/YYYY');
            filterDateFacture.push(item);
          }
        } // Initialisation des valeurs du filtre pour la colonne "date d'échéance"


        if (!filterDateEcheance.find(function (x) {
          return x.value === row.date_echeance;
        })) {
          if (row.date_echeance && typeof row.date_echeance !== 'undefined') {
            var _item = {
              text: row.date_echeance,
              value: row.date_echeance
            };
            _item.text = (0, _moment.default)(String(row.date_echeance)).format('DD/MM/YYYY');
            filterDateEcheance.push(_item);
          }
        }
      });
      this.setFilter('date_facture', filterDateFacture);
      this.setFilter('date_echeance', filterDateEcheance);
      this.getTauxTvaList.forEach(function (row) {
        if (!filterTauxTva.find(function (x) {
          return x.value === row.taux;
        })) {
          if (row.taux && typeof row.taux !== 'undefined') {
            var taux = (0, _utils.toPercentage)(row.taux);
            filterTauxTva.push({
              text: "".concat(taux, " (cpte n\xB0 ").concat(row.taux, ")"),
              value: taux
            });
          }
        }
      });
      this.setFilter('taux_tva', filterTauxTva);
      this.$loader(false);
      return data;
    },
    total: function total() {
      return this.data.length;
    }
  }),
  // 9. Évènements
  // --
  created: function created() {
    var vm = this; // Récupération de la commande parente sélectionnée

    vm.getCommande(function (commande) {
      if (commande) {
        vm.commande = commande;
        vm.idCommande = commande.id;
      }
    });
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('FACTURE_RESET_LIST', function () {
      _this.idCommande = null;
      _this.commande = null;
      _this.dataLoaded = false;

      _this.initFacturation().then(function (_) {
        _this.dataLoaded = true;
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('FACTURE_RESET_LIST');
  },
  // 10. Propriétés non réactives
  // --
  methods: _objectSpread({}, (0, _vuex.mapActions)('facturation', {
    initFacturation: 'init'
  }), {}, (0, _vuex.mapActions)('facturation', ['updateFacture', 'getFactureFichier', 'setFactureCommentaire', 'deleteFactureAndLinkedData', 'bulkDeleteFactureAndLinkedData']), {
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      } // Variable temporaire pour calculer la largeur optimale
      // de chaque colonne en fonction de son contenu


      var columns = {
        'commande': {
          prop: 'commande',
          width: 120
        },
        'tva': {
          prop: 'tva'
        },
        // 'numero': { prop: 'numero' },
        'date_facture': {
          prop: 'date_facture',
          width: 120
        },
        'date_echeance': {
          prop: 'date_echeance',
          width: 120
        },
        'montant': {
          prop: 'montant'
        },
        // 'rev_actu': { prop: 'rev_actu' },
        'retenue_garantie': {
          prop: 'retenue_garantie',
          width: 120
        },
        // 'divers_ttc': { prop: 'divers_ttc' },
        'libelle': {
          prop: 'libelle'
        },
        'emetteur': {
          prop: 'emetteur',
          width: 150
        },
        'mt_avant_paiement_direct_ttc': {
          prop: 'mt_avant_paiement_direct_ttc',
          width: 120
        },
        'mt_paiement_direct_st': {
          prop: 'mt_paiement_direct_st',
          width: 120
        },
        'mt_a_percevoir_ttc': {
          prop: 'mt_a_percevoir_ttc',
          width: 120
        },
        // 'fichier': { prop: 'fichier' },
        // 'nb_reglement': { prop: 'nb_reglement' },
        // 'montant_regle': { prop: 'montant_regle' },
        // 'montant_regle_moins_impute': { prop: 'montant_regle_moins_impute' },
        'montant_ht': {
          prop: 'montant_ht',
          width: 120
        }
      };
      var amountColumns = ['montant', 'rev_actu', 'retenue_garantie', 'divers_ttc', 'mt_avant_paiement_direct_ttc', 'mt_paiement_direct_st', 'mt_a_percevoir_ttc', 'montant_regle', 'montant_regle_moins_impute', 'montant_ht'];
      this.data.forEach(function (row) {
        for (var _i = 0, _Object$entries = Object.entries(columns); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          // console.log('key:', key, 'value.prop:', value.prop, typeof row[value.prop])
          var v = row[value.prop] === null ? '' : row[value.prop].toString();
          var l = (v.length + 2) * 8 + 10;

          if (amountColumns.includes(key)) {
            v = row[value.prop] === null ? '' : (0, _utils.toCurrency)(row[value.prop]).toString();
            l = (v.length + 1) * 8;
          }

          var s = parseInt(columns[key].width);
          s = isNaN(s) ? 0 : s; // console.log('l:', l, 's:', s, typeof s)

          columns[key].width = l > s ? l : s;
        }
      }); // Mise à jour dynamique de la largeur de chaque colonne
      // en fonction de son contenu

      for (var _i2 = 0, _Object$entries2 = Object.entries(columns); _i2 < _Object$entries2.length; _i2++) {
        var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
            key = _Object$entries2$_i[0],
            value = _Object$entries2$_i[1];

        this.columns[key].width = value.width;
      }

      return this.columns[index].width;
    },
    getTotalData: function getTotalData(param, data, libelle) {
      var columns = param.columns;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'libelle') {
          sums[index] = 'Total ' + libelle;
          return;
        }

        if (!['montant', 'montant_ht', 'retenue_garantie', 'mt_avant_paiement_direct_ttc', 'mt_paiement_direct_st', 'mt_a_percevoir_ttc', 'nb_reglement', 'montant_regle'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values); // Formattage

        if (column.property === 'montant' || column.property === 'montant_ht' || column.property === 'retenue_garantie' || column.property === 'montant_regle' || column.property === 'mt_avant_paiement_direct_ttc' || column.property === 'mt_paiement_direct_st' || column.property === 'mt_a_percevoir_ttc') {
          sums[index] = (0, _utils.toCurrency)(sums[index]);
        }
      });
      return sums;
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'libelle') {
          sums[index] = 'Total';
          return;
        }

        if (!['montant', 'montant_ht', 'retenue_garantie', 'mt_avant_paiement_direct_ttc', 'mt_paiement_direct_st', 'mt_a_percevoir_ttc', 'nb_reglement', 'montant_regle'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values); // Formattage

        if (column.property === 'montant' || column.property === 'montant_ht' || column.property === 'retenue_garantie' || column.property === 'montant_regle' || column.property === 'mt_avant_paiement_direct_ttc' || column.property === 'mt_paiement_direct_st' || column.property === 'mt_a_percevoir_ttc') {
          sums[index] = (0, _utils.toCurrency)(sums[index]);
        }
      });
      return sums;
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'date_facture':
          this.filterDateFacture = filter;
          break;

        case 'date_echeance':
          this.filterDateEcheance = filter;
          break;

        case 'taux_tva':
          this.filterTauxTva = filter;
          break;
      }
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column['property'];

      switch (property) {
        case 'tva':
          return (0, _utils.toPercentage)(row[property].taux) === value;

        default:
          return row[property] === value;
      }
    },
    handleChangeDateMin: function handleChangeDateMin(date) {
      this.filterDateMin = date; // EventBus.$emit('REFRESH_CALCULS', { date_max: this.dateMax })
    },
    handleChangeDateMax: function handleChangeDateMax(date) {
      this.filterDateMax = date; // EventBus.$emit('REFRESH_CALCULS', { date_max: this.dateMax })
    },

    /* ---------------------------------------
     *  Liste déroulante des actions
     * ---------------------------------------
     *
     *   - paiement: créer un règlement normal
     *   - commentaire: ajout d'un commentaire à la facture
     *   - visualiser: voir le fichier uploadé
     *   - règlements: voir la liste des règlements de la facture
     *
     */
    handleCommand: function handleCommand(command) {
      switch (command.action) {
        case 'paiement':
          this.handleManageReglement(command.index, command.row); // Attente du prochain rendu du DOM pour demander la création du nouveau règlement

          this.$nextTick(function () {
            _eventBus.EventBus.$emit('REGLEMENT_NEW');
          });
          break;

        case 'commentaire':
          // Afficher une fenêtre modale d'édition de commentaire
          this.row = command.row;
          this.dialogFactureCommentaireVisible = true;
          break;

        case 'visualiser':
          window.open((0, _factures.getFactureFichierUri)(command.row), '_PDF_FACTURE');
          break;

        case 'reglements':
          this.handleManageReglement(command.index, command.row);
          break;
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.row = val;
      this.dialogDetailVisible = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleNewFacture: function handleNewFacture() {
      _eventBus.EventBus.$emit('FACTURE_NEW');
    },
    handleEditFacture: function handleEditFacture(index, row) {
      _eventBus.EventBus.$emit('FACTURE_EDIT', row);
    },
    handleManageReglement: function handleManageReglement(index, row) {
      _eventBus.EventBus.$emit('TAB_MENU', 'reglements_by_facture', row);
    },
    handleViewFile: function handleViewFile(row) {
      this.getFactureFichier(row).then(function (results) {
        var blob = results; // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should

        var newBlob = new Blob([blob], {
          type: 'application/pdf'
        }); // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        } // const fileOfBlob = new File([newBlob], 'mon-fichier.pdf')
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.


        var data = window.URL.createObjectURL(newBlob); // const data = window.URL.createObjectURL(fileOfBlob)

        var link = document.createElement('a');
        link.href = data;
        link.target = 'PDF_FACTURE';
        link.download = row.nom_original;
        link.click(); // window.open(data)

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    },
    handleDeleteFacture: function handleDeleteFacture(index, row) {
      var _this2 = this;

      var msg = "Voulez-vous supprimer la facture ".concat(row.numero, " ?"); // Si la facture est liée à au moins un règlement

      if (row.nb_reglement > 0) {
        msg += "\n          <strong>Attention cette facture est li\xE9e \xE0 ".concat(row.nb_reglement, " r\xE9glement(s).</strong><br>\n          La suppression de cette facture entrainera la suppression de tous les r\xE9glements li\xE9s.\n        ");
      } // Demande de confirmation de suppression


      this.$confirm(msg, 'Confirmer la suppression ?', {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        dangerouslyUseHTMLString: true
      }).then(function (_) {
        _this2.$loader(true); // Suppression de la facture et des réglements liés


        _this2.deleteFactureAndLinkedData(row).then(function (_) {
          _this2.$message('Suppression effectuée avec succès');

          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        }).catch(function (error) {
          _this2.$message(error);
        }).then(function (_) {
          _this2.$loader(false);
        });
      }).catch(function (error) {
        if (error !== 'cancel') {
          console.log(error);

          _this2.$message(error);
        }
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var selection = this.multipleSelection.map(function (u) {
        return u.id;
      }).join(','); // Réglements liés à la sélection de factures

      var reglementNb = this.getNbReglementByFactureOrImputationSelection(selection);
      var msg = 'Voulez-vous supprimer ' + (this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée') + ' ? '; // Si la sélection de factures est liée à au moins un règlement

      if (reglementNb > 0) {
        msg += "\n          <strong>Attention cette s\xE9lection de factures est li\xE9e \xE0 ".concat(reglementNb, " r\xE9glement(s).</strong><br>\n          La suppression de cette s\xE9lection de factures entrainera la suppression de tous les r\xE9glements li\xE9s.\n        ");
      } // Demande de confirmation de suppression


      this.$confirm(msg, 'Confirmer la suppression', {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        dangerouslyUseHTMLString: true
      }).then(function (_) {
        _this3.$loader(true); // Suppression de la sélection de factures et des réglements liés


        _this3.bulkDeleteFactureAndLinkedData(selection).then(function () {
          _this3.$message('Suppression effectuée avec succès');

          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        }).catch(function (error) {
          _this3.$message(error);
        }).then(function (_) {
          _this3.$loader(false);
        });
      }).catch(function (error) {
        if (error !== 'cancel') {
          console.log(error);

          _this3.$message(error);
        }
      });
    },
    actionEditCommentaire: function actionEditCommentaire() {
      var _this4 = this;

      var facture = {
        id: this.row.id,
        commentaire: this.row.commentaire
      };
      this.dialogFactureCommentaireVisible = false;
      this.$loader(true);
      this.setFactureCommentaire(facture).then(function (_) {
        _this4.$message('Mise à jour du commentaire effectuée avec succès'); // this.resetForm()


        _this4.$loader(false);

        _this4.dialogFactureCommentaireVisible = false;

        _eventBus.EventBus.$emit('FACTURE_COMMENTAIRE_SAVE');
      }).catch(function (error) {
        _this4.$loader(false);

        _this4.dialogFactureCommentaireVisible = false;

        _this4.$message(error);
      });
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;