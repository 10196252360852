"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiTypeVente = _interopRequireWildcard(require("@/api/type_vente"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TypeVenteList',
  data: function data() {
    return {
      row: [],
      data: [],
      multipleSelection: [],
      columns: [{
        prop: 'libelle',
        label: 'Libellé',
        width: 150,
        align: 'left'
      }, {
        prop: 'code',
        label: 'code',
        width: 100,
        align: 'left'
      }, {
        prop: 'idc_defaut',
        label: 'Par défaut',
        width: 105,
        align: 'center'
      }, {
        prop: 'actif',
        label: 'Actif',
        width: 105,
        align: 'center'
      }, {
        prop: 'sort',
        label: 'Ordre',
        width: 105,
        align: 'right'
      }],
      filters: [{
        value: '',
        prop: ['libelle', 'code']
      }]
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      apiTypeVente.getAll().then(function (results) {
        results.forEach(function (row) {
          row.idc_defaut = row.idc_defaut === 1 ? '✓' : '';
          row.actif = row.actif === 1 ? '✓' : '';

          _this.data.push(row);
        });
        _this.data.total = results.length;

        _this.$loader(false);
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm("Voulez-vous supprimer le type de vente '".concat(row.libelle, "' ?"), 'Confirmer la suppression ?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiTypeVente.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');

          _this2.$loader(false);
        }).catch(function (err) {
          _this2.$message(err);

          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiTypeVente.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();

          _this3.$loader(false);
        }).catch(function (error) {
          _this3.$message(error);

          _this3.$loader(false);
        });
      });
    }
  }
};
exports.default = _default;