"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Zommer',
  props: {},
  data: function data() {
    return {
      selector: 'tabpanel',
      initZoom: 0.80,
      zoom: 0.80
    };
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.setZoom();
    });
  },
  methods: {
    zoom1: function zoom1() {
      this.zoom = this.initZoom;
      this.setZoom(this.initZoom);
    },
    zoomin: function zoomin() {
      this.zoom += 0.10;
      this.setZoom(0.10);
    },
    zoomout: function zoomout() {
      this.zoom -= 0.10;
      this.setZoom(-0.10);
    },
    setZoom: function setZoom() {
      var _this2 = this;

      var elmnts = document.querySelectorAll('.zoomer');
      elmnts.forEach(function (element) {
        element.style.zoom = _this2.zoom;
      });
    }
  }
};
exports.default = _default;