"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _router = _interopRequireDefault(require("@/router"));

var _auth = require("@/helpers/auth");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* ---------------------------------------
 *  Create an axios instance
 * ---------------------------------------
 *  cf. https://github.com/axios/axios
 *
 */
var service = _axios.default.create({
  // `baseURL` will be prepended to `url` unless `url` is absolute.
  // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
  // to methods of that instance.
  baseURL: process.env.VUE_APP_BASE_API,
  // `withCredentials` indicates whether or not cross-site Access-Control requests
  // should be made using credentials
  // withCredentials: true,
  // `timeout` specifies the number of milliseconds before the request times out.
  // If the request takes longer than `timeout`, the request will be aborted.
  timeout: 1000 * 60 * 5 // en millisecondes

});
/* ---------------------------------------
 *  Request interceptor
 * ---------------------------------------
 *
 *  - Ajout du header 'Authorization' avec le token
 *
 */


service.interceptors.request.use(function (config) {
  if (_store.default.getters.token) {
    config.headers['Authorization'] = (0, _auth.getToken)();
  }

  return config;
}, function (error) {
  // console.log(error)
  return Promise.reject(error);
});
/* ---------------------------------------
 *  Response interceptor
 * ---------------------------------------
 *
 *  - si le 'HTTP status code' vaut 200,
 *    on retour la propriété `data` de la réponse
 *  - sinon on affiche le code de réponse reçu
 *    en tant que message
 *
 */

service.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.status !== 200) {
    (0, _elementUi.Message)({
      message: response.status || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    /*
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      })
    }
    */

    return Promise.reject(new Error(response.status || 'Error'));
  } else {
    return response.data;
  }
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  var message = error.response && error.response.data.message || error.response && error.response.data.sqlMessage || error.message;

  var str = _i18n.default.t(message);

  (0, _elementUi.Message)({
    message: str,
    type: 'error',
    duration: 3 * 1000
  });

  if (error.response.status === 403 || error.response.status === 401) {
    _store.default.dispatch('user/resetToken').then(function () {
      _router.default.push('/login');
    });
  }
  /* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */


  return Promise.reject();
});
var _default = service;
exports.default = _default;