"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailSynthMens',
  data: function data() {
    return {
      refresh: 0,
      YYYYMMmoins1: ''
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.YYYYMMmoins1 = _this.$store.getters.affaire.YYYYMMmoins1 ? (0, _moment.default)(_this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM').format('MM/YYYY') : '';
    });

    this.refresh++; // --
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  }
};
exports.default = _default;