"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByUserId = getAllByUserId;
exports.markAllAsRead = markAllAsRead;
exports.update = update;
exports.markAsDeleted = markAsDeleted;
exports.insert = insert;

var _request = _interopRequireDefault(require("@/helpers/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAllByUserId(id) {
  return (0, _request.default)({
    url: '/notification/user/' + id,
    method: 'get'
  });
}

function markAllAsRead(data) {
  return (0, _request.default)({
    url: '/notification/markallasread',
    method: 'put',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/notification/' + id,
    method: 'put',
    data: data
  });
}

function markAsDeleted(ids) {
  return (0, _request.default)({
    url: '/notification/' + ids,
    method: 'delete'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/notification',
    method: 'post',
    data: data
  });
}