"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _form = _interopRequireDefault(require("./components/form"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      data: {},
      title: 'Ajouter un secteur d\'activité',
      action: 'new'
    };
  },
  components: {
    Form: _form.default
  }
};
exports.default = _default;