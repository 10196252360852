"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByAffaireId = getAllByAffaireId;
exports.insert = insert;
exports.getCurrentPeriodeGestMensByAffaireId = getCurrentPeriodeGestMensByAffaireId;
exports.getAllPeriodeGestMensByAffaireId = getAllPeriodeGestMensByAffaireId;

var _request = _interopRequireDefault(require("@/helpers/request"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/historique/affaire/' + id,
    method: 'get'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/historique',
    method: 'post',
    data: data
  });
}

function getCurrentPeriodeGestMensByAffaireId(id) {
  var data = {
    id: id,
    status: CONST.AFFAIRE.STATUS.VALIDSYNTHESE.value,
    etape: CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
  };
  return (0, _request.default)({
    url: '/historique/gestmens/currentperiode',
    method: 'post',
    data: data
  });
}

function getAllPeriodeGestMensByAffaireId(id) {
  var data = {
    id: id,
    status: CONST.AFFAIRE.STATUS.VALIDSYNTHESE.value,
    etape: CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
  };
  return (0, _request.default)({
    url: '/historique/gestmens/allperiode',
    method: 'post',
    data: data
  });
}