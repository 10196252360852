"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.Routes = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function routesLoader(root, files) {
  var routes = files.keys().reduce(function (routes, path) {
    var name = path.replace(/^\.\/(.*)\.\w+$/, '$1');
    var value = files(path);
    routes[root + '_' + name] = value.default;
    return routes;
  }, {});
  return [].concat.apply([], Object.values(routes));
}

var adminRoutes = routesLoader('admin', require.context('./routes/admin', true, /\.js$/));
var appRoutes = routesLoader('app', require.context('./routes/app', true, /\.js$/));
var rptRoutes = routesLoader('reports', require.context('./routes/reports', true, /\.js$/));
var venteCreanceRoutes = routesLoader('vente-creance', require.context('./routes/vente-creance', true, /\.js$/)); // tri des menus en fonction de la clé "order"

adminRoutes.sort(function (a, b) {
  return a.order - b.order;
});
appRoutes.sort(function (a, b) {
  return a.order - b.order;
});
rptRoutes.sort(function (a, b) {
  return a.order - b.order;
});
var Routes = [{
  path: '/login',
  name: 'Login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login'));
    });
  },
  hidden: true
}, {
  path: '/password-reminder',
  name: 'PasswordReminder',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/password_reminder'));
    });
  },
  hidden: true
}, {
  path: '/password-reset/:pwdResetToken',
  name: 'PasswordReset',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/password_reset'));
    });
  },
  hidden: true
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  name: '401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  name: '/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/layout'));
    });
  },
  redirect: '/affaire/list',
  children: [{
    path: 'home',
    name: 'Home',
    // component: Home
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/home'));
      });
    } // meta: { title: 'Accueil', icon: 'home', breadcrumb: false }

  }]
}, {
  path: '/admin',
  name: 'Admin',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/layout'));
    });
  },
  redirect: 'noRedirect',
  meta: {
    title: 'Administration',
    icon: 'admin'
  },
  children: _toConsumableArray(adminRoutes)
}].concat(_toConsumableArray(appRoutes), [{
  path: '/reports',
  name: 'Rapports',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/layout'));
    });
  },
  redirect: 'noRedirect',
  meta: {
    title: 'Rapports',
    icon: 'report'
  },
  children: _toConsumableArray(rptRoutes)
}, {
  path: '/vente-creance',
  name: 'VenteCreance',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/layout'));
    });
  },
  redirect: 'noRedirect',
  meta: {
    title: 'Vente / Créance',
    icon: 'tva'
  },
  children: _toConsumableArray(venteCreanceRoutes)
}, // 404 page must be placed at the end !!!
{
  path: '*',
  name: '*',
  redirect: '/404',
  hidden: true
}]); // ===========================

exports.Routes = Routes;

_vue.default.use(_vueRouter.default);

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: Routes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
} // const router = new Router({ appRoutes })


var _default = router;
exports.default = _default;